import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  ActionIcon,
  Badge,
  Container,
  createStyles,
  Text,
  Skeleton,
  Popover,
  Divider,
  Loader,
  Table,
  Box,
  NavLink,
  ScrollArea,
} from "@mantine/core";
import { Info } from "phosphor-react";



const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    paddingBottom: "5rem",
    paddingTop: "0.5rem",
    "@media (max-width: 700px)": {
      paddingTop: "0rem",
    },
  },
  leftWrapper: {
    width: "100%",
    flex: 0.7,
    "@media (max-width: 700px)": {
      flex: 1,
    },
  },
}));

{/***********Mostwatched component starts here********
This component appears fixed on the right of the screen inside the "rightbar" component,
and contains the list of the top watched TV shows on TV posts. It ranks the top 10 shows
by number of watchers.*/}

const Mostwatched = () => {

  const { classes } = useStyles();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [mostWatchedArr, setMostWatchedArr] = useState([]);

  useEffect(() => {
    async function getData() {
      axios.get(`${process.env.REACT_APP_API_URL}/mostwatched`)
        .then((res) => {
          setMostWatchedArr(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    getData();
  }, []);


  // Define watchlist and setWatchlist
  const { darkmode, mostwatched, setMostwatched, loading } = useContext(AuthContext); //Use global watchlist state so it persists across all components


  const getWatchlistClick = (id, network, imageUrl, showName) => {
    navigate("/tvshow", {
      state: {
        id: id,
        network: network,
        imageUrl: imageUrl,
        showName: showName
      }
    });
  };


  const getMostWatchedClick = (tvTag) => {
    navigate(`/tv/${tvTag}`);
  };
  const mostWatchedEntries = mostWatchedArr.map((show, index) => (
    <NavLink
      label={show.showName}
      description={show.showNetwork}
      rightSection={
        <Badge size="xs" style={{ backgroundColor: '#1871c1', color: 'white' }} circle>
          {show.watchers}
        </Badge>
      }
      onClick={() => {
        setActive(index);
        getMostWatchedClick(show.tvTag);
        //getWatchlistClick(show.tvTag);
      }}
      icon={
        <img loading="lazy"
          style={{ cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%", }}
          src={show.showAvatar}
          variant="subtle" alt=""
        />
      }
    >

    </NavLink>
  ));

  return (
    <div>
      {!loading ? (
        <div style={{ backgroundColor: darkmode ? "#1A1B1E" : "white", color: darkmode ? "white" : "black", marginBottom: "0.5rem", borderRadius: "4px", }}>
          <div style={{ display: "flex", padding: "0.7rem 1rem 0 1rem", gap: "0.4rem", alignItems: "center", }}>
            <Text weight={700} size={12}>
              Most Watched
            </Text>{" "}

            {/*i tooltip*/}
            <Popover width={220} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <Info style={{ cursor: "pointer", }} size={15} />
              </Popover.Target>
              <Popover.Dropdown>
                <Text size={"xs"}>
                  Top 5 most watched TV shows ranked by number of watchers.
                </Text>
              </Popover.Dropdown>
            </Popover>
          </div>

          {/*Watchlist Entries*/}
          <ScrollArea h={300} type="auto" scrollbarSize={20} offsetScrollbars>
            {mostWatchedEntries}
          </ScrollArea>
        </div>
      )
        : (
          <div style={{ paddingBottom: "0.7rem", }} className={classes.accounts}>

            {new Array(4).fill(0).map((_, index) => {

              return (

                <div style={{ display: "flex", alignItems: "center", }} key={index} className={classes.account}>
                  <Skeleton style={{ width: "40px", height: "40px", borderRadius: "50%", }} />

                  <div style={{ display: "flex", flex: 1, flexDirection: "column", }}>
                    <Skeleton width={"100px"} height={8} radius="xl" />
                    <Skeleton width={"60px"} height={8} mt={10} radius="xl" />
                  </div>
                </div>
              );
            })}
          </div>

        )}

    </div>
  );

};
export default Mostwatched;