import { ActionIcon, Container, createStyles, List, Text } from "@mantine/core";
import { ArrowLeft } from "phosphor-react"; //@phosphor-icons/react
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Components/Sidebar";
import { AuthContext } from "../context/Auth";
const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    paddingBottom: "5rem",
    paddingTop: "0.5rem",
    "@media (max-width: 700px)": {
      paddingTop: "0rem",
    },
  },
  leftWrapper: {
    flex: 0.7,
    "@media (max-width: 700px)": {
      flex: 1,
    },
  },
}));

export const About = () => {
  const { classes } = useStyles();
  const { darkmode } = useContext(AuthContext);
  const navigate = useNavigate();
  const features = [
    "Create posts with text and images",
    "Like and comment on posts",
    "Like comments and reply to them",
    "Chat with other users in real time",
    "Mention users in posts and comments",
    "Quote other posts",
    "Search for users and posts",
    "Sort posts and comments by date or popularity",
    "Follow other users",
    "Customize profile with profile picture and description",
    "Receive notifications for likes, comments, and new followers",
    "Authenticate using Google or normal login with username and password",
    "Leaderboard system based on total points, where users can compete to reach the top",
    "Earn points by creating new posts, receiving likes, and gaining new followers",
  ];

  return (
    <Container px={0} className={classes.wrapper}>
      <div className={classes.leftWrapper}>
        <div
          style={{
            backgroundColor: darkmode ? "#1A1B1E" : "white",
            color: darkmode ? "white" : "black",
            padding: "1rem 0rem 0rem 1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ActionIcon onClick={() => navigate(-1)}>
            <ArrowLeft size="20px" />
          </ActionIcon>
        </div>
        <div
          style={{
            backgroundColor: darkmode ? "#1A1B1E" : "white",
            padding: "1rem",
          }}
        >
          <Text size={"44px"} weight={900} color={darkmode ? "white" : "black"}>
            About TV Posts
          </Text>
          <Text
            color={darkmode ? "#a6a7ab" : "#343a40"}
            style={{ marginTop: "1rem" }}
          >
            Welcome to TV Posts – the fun and easy way to chat about your favorite shows!
          </Text>
          <Text
            color={darkmode ? "#a6a7ab" : "#343a40"}
            style={{ marginTop: "1rem" }}
          >
            This site is created for TV show fans, by TV show fans. We're passionate about providing a safe space where you can track your favorite shows, share awesome moments, and make new friends.
          </Text>
          <Text
            color={darkmode ? "#a6a7ab" : "#343a40"}
            style={{ marginTop: "1rem" }}
          >
            TV Posts is 100% ad-free and maintained by a small team. We welcome donations to help keep our servers running. Every contribution helps us improve and bring more exciting features to you.
          </Text>
         

          <Text
            color={darkmode ? "#a6a7ab" : "#343a40"}
            style={{ marginTop: "1rem" }}
          >
            To report bugs or provide feedback please reach out to us on the TV Posts Discord:{" "}
            <a
              target="_blank"
              style={{
                color: darkmode ? "#4dabf7" : "#1c7ed6",
              }}
              href="https://discord.gg/QVcVaeJ8uH"
              rel="noreferrer"
            >
            <br></br> TV Posts Discord
            </a>{" "}
          </Text>
          
        </div>
      </div>

      {/*<Sidebar />*/}
    </Container> //Sidebar displays the leaderboard
  );
};
