import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

const HashtagHighlight = Extension.create({
  name: 'hashtagHighlight',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('hashtagHighlight'),
        appendTransaction(transactions, oldState, newState) {
          const transaction = newState.tr
          let modified = false

          newState.doc.descendants((node, pos) => {
            if (node.isText) {
              const regex = /#\w+\b/g
              let match

              // Clear existing hashtag marks
              transaction.removeMark(pos, pos + node.nodeSize, newState.schema.marks.hashtagMark)

              // Apply new hashtag marks
              while ((match = regex.exec(node.text)) !== null) {
                transaction.addMark(pos + match.index, pos + match.index + match[0].length, newState.schema.marks.hashtagMark.create())
                modified = true
              }
            }
          })

          return modified ? transaction : null
        },
      }),
    ]
  },
})

export default HashtagHighlight

/*regex.exec(node.text): This checks if the text of the node matches the 
regular expression /#\w+\b/g, which matches hashtags (a # followed by one or more word
characters, ending at a word boundary).*/