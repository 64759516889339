import { useEffect, useState, useContext } from "react";
import { Button, Container, createStyles, Loader, Text } from "@mantine/core";
import { PostFeed } from "../../Components/PostFeed";
import { Sidebar } from "../../Components/Sidebar";
import CreatePostModal from "../../Components/CreatePostModal";
import { followinguserposts, HomePosts } from "../../api/GET";
import { AuthContext } from "../../context/Auth";
import { showNotification } from "@mantine/notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ClockCounterClockwise,
  Sparkle,
  UserList,
  WarningCircle,
} from "phosphor-react";
import Leaderboardhorizontal from "../../Components/Leaderboardhorizontal";
import { Rightbar } from "../../Components/Rightbar";
import Trendingticker from "../../Components/Trendingticker";


const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    paddingBottom: "5rem",
    paddingTop: "0.5rem",
    "@media (max-width: 700px)": {
      paddingTop: "0rem",
    },
  },
  leftWrapper: {
    width: "100%",
    flex: 0.9,
    "@media (max-width: 700px)": {
      flex: 1,
    },
  },
  sortby: {
    borderRadius: "4px",
    "@media (max-width: 700px)": {
      borderRadius: "0px",
    },
  },
}));
export const Home = () => {
  const { classes } = useStyles();
  const [homePosts, setHomePosts] = useState([]);
  const { UserInfo, darkmode } = useContext(AuthContext);
  const [loading, setloading] = useState(true);
  const [page, setpage] = useState(0);
  const [postCount, setpostCount] = useState(0);
  const [sortby, setsortby] = useState("Latest");
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setsortby("Latest");
  }, [UserInfo]);
  useEffect(() => {
    const fetchposts = async () => {
      setpage(0);
      setloading(true);
      if (sortby === "Following") {
        await followinguserposts(0)
          .then((res) => {
            setHomePosts(res.data.homeposts);
            setloading(false);
            setpostCount(res.data.postCount);
          })
          .catch((err) => {
            if (err.response.status === 0) {
              showNotification({
                icon: <WarningCircle size={18} />,
                color: "red",
                title: "Internal Server Error",
                autoClose: 4000,
              });
            } else {
              showNotification({
                icon: <WarningCircle size={18} />,
                color: "red",
                title: err.response.data,
                autoClose: 4000,
              });
            }
          });
      } else {
        await HomePosts(0, sortby)
          .then((res) => {
            setHomePosts(res.data.homeposts);
            setloading(false);
            setpostCount(res.data.postCount);
          })
          .catch((err) => {
            if (err.response.status === 0) {
              showNotification({
                icon: <WarningCircle size={18} />,
                color: "red",
                title: "Internal Server Error",
                autoClose: 4000,
              });
            } else {
              showNotification({
                icon: <WarningCircle size={18} />,
                color: "red",
                title: err.response.data,
                autoClose: 4000,
              });
            }
          });
      }
    };
    fetchposts();
  }, [sortby]);

  const fetchMoreData = async () => {
    console.log('fetchMoreData is running'); // Add this line
    setpage((prev) => prev + 1);
    if (sortby === "Following") {
      followinguserposts(page + 1)
        .then((res) => {
          setHomePosts((prev) => [...prev, ...res.data.homeposts]);
        })
        .catch((err) => {
          if (err.response.status === 0) {
            showNotification({
              icon: <WarningCircle size={18} />,
              color: "red",
              title: "Internal Server Error",
              autoClose: 4000,
            });
          } else {
            showNotification({
              icon: <WarningCircle size={18} />,
              color: "red",
              title: err.response.data,
              autoClose: 4000,
            });
          }
        });
    } else if (sortby === "Popular" || sortby === "Latest") {
      await HomePosts(page + 1, sortby)
        .then((res) => {
          console.log('fetchMoreData res:', res.data.homeposts); // Log to check if posts are fetched
          setHomePosts((prev) => [...prev, ...res.data.homeposts]);
        })
        .catch((err) => {
          if (err.response.status === 0) {
            showNotification({
              icon: <WarningCircle size={18} />,
              color: "red",
              title: "Internal Server Error",
              autoClose: 4000,
            });
          } else {
            showNotification({
              icon: <WarningCircle size={18} />,
              color: "red",
              title: err.response.data,
              autoClose: 4000,
            });
          }
        });
    }
  };


  {/***********Home Rendering Starts Here*********/ }

  return (
    <Container px={0} size="xl" className={classes.wrapper} style={{ width: "100%" }}>
      {UserInfo ? <Sidebar style={{ width: "100%" }} /> : null}

      {/*Middle Container Starts Here */}

      <div className={classes.leftWrapper}>
        {" "}

        {/*If UserInfo is null, undefined, or any other falsy value, CreatePost won't render.*/}


        {UserInfo && (
          <div
            className={
              darkmode ? "createpostmodalradiusdark" : "createpostmodalradius"
            }
            style={{
              padding: "1rem",
              backgroundColor: darkmode ? "#1A1B1E" : "white",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
            >

              <div
                onClick={() => setOpened(true)}
                className="createpostdiv"
                style={{
                  width: "100%",
                  cursor: "pointer",

                  padding: "0.7rem",
                  fontSize: "13px",
                  backgroundColor: darkmode ? "#1A1B1E" : "white",
                  color: "grey",
                }}
              >
                Share your thoughts on a show (use ~ to tag a show: eg. ~StrangerThings)
              </div>
            </div>
          </div>
        )}

        <CreatePostModal
          opened={opened}
          setOpened={setOpened}
          UserInfo={UserInfo}
          ShowImdbid={null}
          tvTag={null}
          setTvShowPosts={setHomePosts}
          runEffect={false}
        />

        <div
          className={classes.sortby}
          style={{
            display: "flex",
            gap: "0.3rem",
            padding: "1rem 0.5rem",
            backgroundColor: darkmode ? "#1A1B1E" : "white",//
            marginBottom: "0.5rem ",
            overflow: "auto",
          }}
        >
          <Button
            leftIcon={<ClockCounterClockwise size={20} />}
            onClick={() => setsortby("Latest")}
            variant={sortby === "Latest" ? "filled" : "subtle"}
            size="xs"
            radius={"xl"}
            color={"gray"}
          >
            Latest
          </Button>
          <Button
            leftIcon={<Sparkle size={20} />}
            onClick={() => setsortby("Popular")}
            variant={sortby === "Popular" ? "filled" : "subtle"}
            size="xs"
            radius={"xl"}
            color={"gray"}
          >
            Popular
          </Button>

          <Button
            leftIcon={<UserList size={20} />}
            onClick={() => setsortby("Following")}
            variant={sortby === "Following" ? "filled" : "subtle"}
            size="xs"
            radius={"xl"}
            color={"gray"}
          >
            Following
          </Button>
        </div>
       {/*  <Leaderboardhorizontal /> Mobile Horizontal Leaderboard*/}
        <InfiniteScroll
          dataLength={homePosts.length}
          next={() => {
            console.log('InfiniteScroll triggered!!!!!!!!!!!'); // Log to check if InfiniteScroll is triggered
            fetchMoreData(); // Your function to fetch more posts
          }}
          hasMore={postCount > homePosts.length}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Loader />
            </div>
          }
          endMessage={
            <>
               <Text
                  style={{
                    marginTop: "1rem",
                  }}
                  align="center"
                  color={darkmode ? "white" : "dark"}
                >
                  You have seen it all
                </Text> 
            </>
          }
        >
          <PostFeed
            setPosts={setHomePosts}
            posts={homePosts}
            loading={loading}
          />
        </InfiniteScroll>

      </div>
      <Rightbar />
    </Container>

  );
};
