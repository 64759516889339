import { useContext, useEffect, useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Text,
  ActionIcon,
  Alert,
  TextInput,
  Input,
  Image,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Bluetooth,
  ChatCircleDots,
  House,
  MagnifyingGlass,
  MoonStars,
  Binoculars,
  Sun,
} from "phosphor-react";
import logoImage from "../assests/tv-posts-logo-transparent-white.png";
import { ReactComponent as StarList } from '../Icons/list-star raw.svg';
import { ProfileMenu } from "./ProfileMenu";
import Notis from "../views/Notis/Notis";
import { AuthContext } from "../context/Auth";
import Searchbox from "./Searchbox";
import Watchlist from "./Watchlist";
import { Modal } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 99,
    "@media (min-width: 700px)": {
      borderBottom: "none",
      // backgroundColor: "yellow",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%", //100% of root element
    //border: "2px solid blue",
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  searchbox: {
    display: "none", // by default, hide the searchbox
    "@media (min-width: 767px)": {
      display: "block", // on larger screens, always show the searchbox
    },
  },

  mobileSearchIcon: {
    display: "block",
    "@media (min-width: 767px)": {
      display: "none",
    },
  },

  watchlistIcon: {
    display: "block",
    "@media (min-width: 767px)": {
      display: "none",
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
  searchboxMobile: {
    width: '90%', // make the Searchbox take up the full width of its parent
    maxWidth: 'calc(100% - 40px)', // ensure the Searchbox doesn't exceed its parent's width
    boxSizing: 'border-box', // include padding and border in the element's total width
    padding: 0, // remove padding
    margin: 0, // remove margin
  },
  modalMobile: {
    width: '90%', // reduce the width to 90% of the screen width
    maxWidth: '100%', // reduce the max-width to 90% of the screen width
    boxSizing: 'border-box', // include padding and border in the element's total width
    padding: '20px', // remove padding
    margin: '0 auto', // center the modal horizontally with automatic margins
  },
}));

export function Navbar({ socket }) {
  const { UserInfo, setdarkmode, darkmode } = useContext(AuthContext);
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [noti, setnoti] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isWatchlistOpen, setIsWatchlistOpen] = useState(false);




  useEffect(() => {
    const handleNewNotification = (data) => {
      setnoti(data);

      setTimeout(() => {
        setnoti(null);
      }, 4000);
    };

    socket.on("newnotification", handleNewNotification);

    return () => {
      socket.off("newnotification", handleNewNotification);
    };
  }, []);

  return (
    <Header
      style={{
        zIndex: 899,
      }}
      height={60}
      mb={0}
      className={classes.root}
    >
      {noti && (
        <Alert
          onClose={() => {
            setnoti(null);
          }}
          style={{
            cursor: "pointer",
            position: "fixed",
            top: "30px",
            left: "0px",
            right: "0px",
            zIndex: 999,
            width: "350px",
            margin: "auto",
          }}
          withCloseButton
          color={darkmode ? "gray" : "dark"}
          variant="filled"
        >
          <div
            onClick={() => {
              navigate(
                noti?.postId ? `/post/${noti?.postId}` : `/${noti?.username}`
              );
            }}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
              }}
              src={noti?.avatar}
              alt=""
            />

            <Text size={"15px"}>
              <Text component="span" weight={500}>
                {noti?.username}
              </Text>{" "}
              {noti?.type}
            </Text>
          </div>
        </Alert>
      )}

      <Container className={classes.header}>
        <div style={{ display: "flex", gap: "2rem", alignItems: "center", width: "100%" }}>

          {/* Logo with onClick */}
          <div
            style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
            onClick={() => {
              if (pathname === "/") {
                window.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                navigate("/");
              }
            }}
          >
            <Image src={logoImage} alt="Logo" height={45} />
          </div>
          {/* Searchbox without onClick */}

          <Searchbox className={classes.searchbox} />

          {/*<Text color={darkmode ? "white" : "black"} size="xl" weight="700">
    TV Posts
        </Text> 
  <Input style={{ flex: 1, minWidth: "400px", width: "100%" }} icon={<MagnifyingGlass size={16} />} placeholder="Search TV Posts" />*/}
        </div>
        {/* //This sets the width of the input field to be 100% of its parent container's width. 
        This means the input field will take up the full width of its container.
        onChange={(e) => {
        setSearch(e.target.value);
        }} //This sets up an event handler for the onChange event, which is fired every time the user types into the input field. The setSearch function is called with the new value of the input field every time the user types a character.
        value={search} //This sets the value of the input field to be whatever the current value of search is. This makes the input field a controlled component in React. */}
        <Group spacing={5} className={classes.links}>
          {/* {items} */}
          <div style={{ display: "flex", gap: "1rem", alignItems: "right" }}>
            {UserInfo && (
              <ActionIcon
                className={classes.watchlistIcon}
                onClick={() => {
                  setIsWatchlistOpen(true);
                }}
              >
                <StarList color={darkmode ? "white" : "black"} size={32} />
              </ActionIcon>
            )}
            <Modal
              opened={isWatchlistOpen}
              onClose={() => setIsWatchlistOpen(false)}
              title=""
              size="sm"
            >
              <Watchlist />
            </Modal>
            <>
              <ActionIcon
                className={classes.mobileSearchIcon}
                onClick={() => setIsOpen(true)}
              >
                {/*Commented out the lollipop search icon*/}
                <MagnifyingGlass
                  color={darkmode ? "white" : "black"}
                  size={28} //This is a phosphor-react component that represents a magnifying glass icon. It is nested inside the <ActionIcon> component. It has two props:color={darkmode ? "white" : "black"}: This prop sets the color of the magnifying glass icon. The color value is conditionally determined based on the darkmode variable. If darkmode is true, the color is set to "white"; otherwise, it is set to "black". This allows the icon's color to adapt based on the dark or light mode of the application. size={28}: This prop sets the size of the magnifying glass icon to 28. It defines the width and height of the icon in pixels.
                />
              </ActionIcon>

              <Modal
                opened={isOpen}
                onClose={() => setIsOpen(false)}
                title="Search"
                size="sm"
                className={classes.modalMobile}
              >
                <Searchbox
                  className={classes.searchboxMobile}
                  closeModal={() => setIsOpen(false)}
                /> {/* This is for mobile view */}
              </Modal>

                
                {/*Commented out the messages icon*/}
                <ActionIcon> 
                  <ChatCircleDots
                    onClick={() => {
                      navigate("/chatrooms");
                    }}
                    color={darkmode ? "white" : "black"}
                    size={28}
                  />
                  </ActionIcon>
                {UserInfo && ( <Notis darkmode={darkmode} /> )}
              </>
                
            {!UserInfo && (
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  setdarkmode(!darkmode);
                  if (darkmode) {
                    document.body.style.backgroundColor = "#f0f2f5";
                  } else {
                    document.body.style.backgroundColor = "#101113";
                  }
                  localStorage.setItem("darkmode", !darkmode);
                }}
              >
                {darkmode ? (
                  <Sun color="#ffd43b" size={28} />
                ) : (
                  <MoonStars color="#228be6" size={28} />
                )}
              </ActionIcon>
            )}

            <ActionIcon>
              <ProfileMenu socket={socket} />
            </ActionIcon>
          </div>
        </Group>
      </Container>
    </Header>
  );
}
