import { ActionIcon, Container, createStyles, List, Text } from "@mantine/core";
import { ArrowLeft } from "phosphor-react"; //@phosphor-icons/react
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/Auth";

const useStyles = createStyles(() => ({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
        gap: "1rem",
        paddingBottom: "5rem",
        paddingTop: "0.5rem",
        "@media (max-width: 700px)": {
            paddingTop: "0rem",
        },
    },
    leftWrapper: {
        flex: 0.7,
        "@media (max-width: 700px)": {
            flex: 1,
        },
    },
}));

const PrivacyPolicy = () => {
    const { classes } = useStyles();
    const { darkmode } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <Container px={0} className={classes.wrapper}>
            <div className={classes.leftWrapper}>
                <div
                    style={{
                        backgroundColor: darkmode ? "#1A1B1E" : "white",
                        color: darkmode ? "white" : "black",
                        padding: "1rem 0rem 0rem 1rem",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <ActionIcon onClick={() => navigate(-1)}>
                        <ArrowLeft size="20px" />
                    </ActionIcon>
                </div>
                <div
                    style={{
                        backgroundColor: darkmode ? "#1A1B1E" : "white",
                        padding: "1rem",
                    }}
                >
                    <Text size={"44px"} weight={900} color={darkmode ? "white" : "black"}>
                        TV Posts Privacy Policy
                    </Text>
                    <Text
                        color={darkmode ? "#a6a7ab" : "#343a40"}
                        style={{ marginTop: "1rem" }}
                    >
                        Updated: July 7, 2024
                    </Text>
                    <Text
                        color={darkmode ? "#a6a7ab" : "#343a40"}
                        style={{ marginTop: "1rem" }}
                    >
                        <p>This Privacy Policy describes the privacy policies and procedures of TV Posts, Inc., and their subsidiaries and affiliates (collectively “TV Posts”, “we”, “us”, or “our”) in connection with personal data that we collect through our websites on which a link to this Privacy Policy is displayed, through our marketing initiatives, and in connection with our interactions with you through our websites, apps, and other services (collectively referred to as the "Services”).</p>

                        <p>Before using the Services or submitting any personal information to TV Posts, please review this Privacy Policy carefully and contact us at info@tvposts.com if you have any questions. If you do not agree to this Privacy Policy, please do not access our websites or otherwise use the Services.</p>

                        <h4>I. Personal Information We Collect</h4>
                        <h5>Information You Provide to Us include:</h5>

                        <ul>
                            <li>Contact information and communications: When you interact with us, including through web forms, phone, email, post or social media, we may collect your name, contact information (such as phone numbers and email address), any other information you choose to provide and the content of your communications.</li>
                            <li>Registration information: To use certain features of the Services, you will need to register and create an account. When you create an account, you will be asked for basic registration information, such as an email address, username, password, and mobile phone number.</li>
                            <li>Account information: We collect information you provide when using your TV Posts account, such as your profile information, account settings, subscriptions and content you create or interact with while using your account, including your posts, replies, likes, shares, symbols, watchlists, follows, followers and messages.</li>
                            <li>Payment information: We collect payment information about you through our payment service providers when you sign up for any of our subscriptions or make any other payments to us, such as your name, payment card information and billing information. Please note that we do not store payment card numbers on our systems and only retain a tokenized version of such information. The information you provide in connection with your payments is handled by our third-party payment processors in accordance with their terms of service and privacy policies.</li>
                            <li>Marketing information: When you register to receive any kind of marketing communication from us or otherwise interact with our marketing communications, we may collect your name, contact details, marketing preferences and details about your engagement with them.</li>
                            <li>Surveys, reviews and testimonials: When you participate in and complete any of our surveys or when you write a review/testimonial about us or any of our products, we may collect your name, job role, contact information, survey responses and the content of your review/testimonial.</li>
                            <li>Suppliers/partners and representatives: When you enter into a supplier/partnership agreement or relationship with us or are a representative of a supplier/partner, we may collect your name, company and job role details, contact information and the content of your communications with us.</li>
                            <li>Other information: We may request or receive other personal information not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</li>
                        </ul>
                        <h5>Information We Obtain from Third Parties</h5>

                        <ul>
                            <li>Social media information: We have pages on social media sites like Instagram, Facebook, Twitter, Medium, Vimeo, YouTube, and LinkedIn (“Social Media Pages”). When you interact with our Social Media Pages, we will collect personal information that you elect to provide to us, such as your contact details. In addition, the companies that host our Social Media Pages may provide us with aggregate information and analytics regarding the use of our Social Media Pages.</li>
                            <li>Third-party credentials: You may also enter certain passwords, usernames, account numbers, and other account information for websites, apps, products, or services provided by third-party service providers ("Third-Party Services").</li>
                        </ul>

                        <h4>II. Automatic Data Collection</h4>
                        <p>We and our service providers may automatically log and combine information about you, your computer or mobile device, and your interaction over time with the Services, our communications and other online services, such as:</p>

                        <ul>
                            <li>Device data such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, device type (e.g., phone, tablet), IP address, device identifiers, language settings. and general location information such as city, state, or geographic area. If you choose to use location-enabled Services, we may collect location information from your device. You may control or disable the sharing of location information via your device settings and/or your browser settings.</li>
                            <li>Online Activity data such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Services, navigation paths between pages or screens, information about your activity on a page or screen, access times, duration of access, and whether you have opened or otherwise engage with our communications, such as our marketing emails or clicked links or files within them.</li>
                        </ul>
                        <p>We use cookies, web beacons (e.g. pixel tags) browser web storage (also known as locally stored objects, or “LSOs”), and similar technologies to collect some of this information. Please see our Cookie Policy https://tvposts.com/about/legal/cookie-policy for more information on how we use these technologies.</p>

                        <h4>III. How We Use Your Personal Information</h4>
                        <p>We use your personal information for the following purposes and as otherwise described in this Privacy Policy or at the time of collection:</p>

                        <ul>
                            <li>Provide the Services. We use personal information to operate, maintain, and provide you with our Services, including ensuring you can use and access our website and your TV Posts account. In particular, we will use personal information to perform our contractual obligations under our Terms of Service.</li>
                            <li>Communicate with you about the Services, To perform our contractual obligations to you, or when it is in our legitimate business interests to do so, we will use your personal information to respond to your requests, provide support, and communicate with you about our Services, including by sending announcements, updates, security alerts and support and administrative messages.</li>
                            <li>Improve, monitor, personalize, and protect the Services. It is in our legitimate business interests to maintain and improve the quality of our Services, and keep our Services safe for our users, which includes:
                                <ul>
                                    <li>Understanding your needs and interests, and personalizing your experience with the Services and our communications;</li>
                                    <li>Troubleshooting, testing and research, and to keep our IT systems, architecture, networks and the Services secure; and</li>
                                    <li>Investigating and protecting against fraudulent, harmful, unauthorized, or illegal activity.</li>
                                </ul>
                            </li>


                            <li>For research, development, market analysis, and benchmarking. We may use personal information for research and product improvement and development purposes, as well as for market analysis and benchmarking purposes, in our legitimate business interests to analyze and improve the Services and overall business. We may also allow others to access public information on our Services to analyze market trends and for other business purposes. As part of these activities, we may create or use aggregated, de-identified or anonymized data derived from the personal information we collect. We may use this data and share it with third parties for our lawful business purposes, including to analyze, improve or add features to our Services, and to promote our business.</li>
                            <li>Surveys and reviews. It is in our legitimate business interest to conduct surveys and collect feedback from reviews you provide to us. Where we intend to publish your survey responses or reviews with information that identifies you we will ask you for your consent to be able to process this information.</li>
                            <li>Marketing and advertising
                                <ul>
                                    <li>Direct marketing. We may send you direct marketing communications about our products and Services that we think may be of interest to you as permitted by law, including by email. You may opt out of our marketing communications as described below. Where required by law, we will only send you marketing information if you consent to us doing so. Otherwise, we will only send you marketing information if it is in our legitimate business interests to send such information.</li>
                                    <li>Interest-based advertising and third-Party ad serving. We engage with advertising partners, including third party advertising companies and social media companies, to display ads to you on our website and when you visit other websites. These partners may use cookies and similar technologies to collect information (including the automatically-collected data described above) about your interactions over time across our Services and your other online activities, and use that information to serve online ads that they think will interest you and to measure the effectiveness of our ads. We also display online ads on our Services on behalf of third-party advertisers and affiliates, as publisher. These ads may include cookies set by these third parties, which are placed on your device when you interact with their ads. For more details on the specific cookies we use, please see our Cookie Policy https://TV Posts.com/about/legal/cookie-policy. Where required by law we will undertake such direct marketing and online advertising activities on the basis of your consent, otherwise we will do so only if it is in our legitimate business interests to undertake such activities. Users that sign-up for a subscription can access the Services without viewing ads on the Services.</li>
                                    <li>Supplier/partner relationships. Where you are a supplier/partner or a representative of a supplier/partner, it is in our legitimate business interests to use your personal information to contact you and to manage our relationship with you and/or your company. If we have entered into a contract with you/your company, we will use your personal information as required for us to perform our contractual obligations under the relevant contract.</li>
                                </ul>
                            </li>
                            <li>Compliance and protection. We may use personal information to comply with legal obligations, and to defend ourselves against legal claims or disputes where it is in our legitimate business interests to do so, including to:
                                <ul>
                                    <li>Protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                                    <li>Audit our internal processes for compliance with legal and contractual requirements and internal policies;</li>
                                    <li>Enforce the terms and conditions that govern the Services;</li>
                                    <li>Prevent, identify, investigate, and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft; and</li>
                                    <li>Comply with applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.</li>
                                </ul>
                            </li>
                        </ul>

                        <h4>IV. Information Sharing</h4>
                        <p>We may share your personal information with the following categories of recipients:</p>

                        <ul>
                            <li>Service providers. Trusted third parties we engage to perform functions and provide services to us, including, without limitation, hosting and maintenance and other information technology services, only to the extent necessary to perform these functions and provide such services, and pursuant to binding contractual obligations requiring such third parties to maintain the privacy and security of your data.</li>
                            <li>Advertising partners. Third party advertising partners, including for the interest-based and third party ad serving and measurement purposes described above. For example, we may share information with advertising partners to display ads about our Services to you and others on other third-party websites and platforms. We may also share information with third parties that display advertising about their products and services on our Services, including to allow them to analyze and improve the effectiveness of their advertising campaigns.</li>
                            <li>Competent authorities and others. Government, law enforcement officials or private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.</li>
                            <li>Business transfers. Acquirers and other relevant participants in connection with business transactions (or negotiations for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, dissolution, transition of service to another provider, or other disposition of all or any portion of the business or assets of, or equity interests in TV Posts (including in the event of bankruptcy or similar proceedings).</li>
                            <li>Affiliates. With our current and future affiliates, meaning an entity that controls, is controlled by, or is under common control with TV Posts.</li>
                            <li>Other users and the general public. TV Posts is a public platform, which means the information that appears on your TV Posts public profile (including your photo, bio, name, location, website, follows, posts, replies, likes, and watchlists) will be visible to other users of the Services and members of the general public. By using the Services, you are directing us to share this information publicly and freely. We may also provide publicly available information to our partners for their own business, research and analytics purposes, which may include displaying an extract of the public TV Posts user feed on their finance related websites through our APIs. You acknowledge that by sharing information on your profile and interacting with posts and other users, you make this personal information available to other users and the general public.</li>
                            <li>Social media. We may share your public content on our YouTube, Instagram, Facebook, Twitter, Medium, Vimeo, YouTube, or LinkedIn page.</li>
                            <li>Professional advisors. We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</li>
                            <li>Third-Party Services. We may disclose your personal information to Third-Party Services if you have enabled features or functionality connecting the Services to the third-party platform (such as by logging in to the Services using your account with the third-party, providing your API key or similar access token for the Services to a third-party, or otherwise linking your TV Posts account to a third-party’s services). Please refer to the third-party’s privacy policy for more information about how they use your personal information.</li>
                        </ul>

                        <h4>V. Data Retention</h4>
                        <p>We retain personal information only for as long as is necessary to fulfill the purposes described in this Privacy Policy, including to provide the Services and where we have a legitimate business need to do so, such as where the retention is required by law or regulatory obligations (e.g. tax, legal, or accounting purposes), to enforce and prevent violations of the terms and conditions of the Services, or to defend legal claims. with applicable laws and regulatory obligations.</p>

                        <p>To determine the appropriate retention period for your personal data, we will consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we use your personal data and whether we can achieve those purposes through other means, any permissions you give us with regards to your personal information, and the applicable legal and regulatory requirements.</p>

                        <h4>VI. Cross-Border Data Transfer</h4>
                        <p>You provide personal information directly to us in the United States when using the Services. We may also transfer personal information to our affiliates and service providers in the United States and other jurisdictions. Please note that such jurisdictions may not provide the same protections as the data protection laws in your home country.</p>

                        <p>Where there are cross border transfers of your personal information we will ensure that relevant safeguards are in place to afford adequate protection for your personal information, and we will comply with applicable data protection laws, For example, in relation to European personal information, we rely on an adequacy decision by the European Commission or the UK Government, or on pre-approved contractual protections for the transfer of your personal information. For more information about how we transfer personal information internationally, please contact us as set out below.</p>

                        <h4>VII. Your Choices and Rights</h4>
                        <p>Access or update your information. If you have registered for an account with us, you may review and update certain personal information in your account profile by logging into the account. Opt-out of marketing communications. You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of our marketing emails, or by contacting us at info@tvpsts.com. You may continue to receive service-related and other non-marketing emails.</p>

                        <p>Personal information requests. Depending on your location and the nature of your interactions with our services, you may request the following in relation to personal information:</p>

                        <ul>
                            <li><strong>Access.</strong> Provide you with information about the processing of your personal information and give you access to a copy of the personal information that we have collected about you.</li>
                            <li><strong>Correction.</strong> Update or correct personal information that is inaccurate or out of date.</li>
                            <li><strong>Deletion.</strong> Delete your personal information that we no longer need to provide the services or for other lawful purposes.</li>
                            <li><strong>Portability.</strong> Provide your personal information in a portable, machine-readable, readily usable format to you or a third party of your choice.</li>
                            <li><strong>Additional rights,</strong> such as to object to our processing of your personal information, request that we restrict our use of personal information, and where applicable, you may withdraw your consent to our processing of your personal information.</li>
                        </ul>

                        <p>To make a request, please email us or write to us as provided in the Contact Us section below. We may ask for specific information from you to help us confirm your identity. Depending on where you reside, you may be entitled to empower an authorized agent to submit requests on your behalf. We will require authorized agents to confirm their identity and authority, in accordance with applicable laws.</p>

                        <p>In some instances, your choices may be limited, such as where fulfilling your request would impair the rights of others, our ability to provide a service you have requested, or our ability to comply with our legal obligations and enforce our legal rights. If you are not satisfied with how we address your request, you may submit a complaint by contacting us as provided in the Contact Us section below, or by submitting a complaint to the data protection regulator where you live, work, or where you feel a violation has occurred.</p>

                        <p><strong>Choosing not to share your personal information.</strong> Where we are required by law to collect your personal information, or where we need your personal information in order to provide the Services to you, if you do not provide this information when requested (or you later ask to delete it), we may not be able to provide you with our Services. We will tell you what information you must provide to receive the Services by designating it as required at the time of collection or through other appropriate means.</p>

                        <p><strong>Third-party platforms.</strong> If you choose to connect to the Services via a third-party platform, you may have the ability to limit the information that we may obtain from the third-party at the time you login to the Services using the third-party’s authentication service or otherwise connect your account. Subsequently, you may be able to control your settings through the third-party’s platform or service. If you withdraw our ability to access certain information from a third-party platform, that choice will not apply to information that we have already received from that third-party.</p>

                        <p><strong>Limit online tracking and cookies.</strong> As part of our commitment to transparency and your privacy, our Cookie Policy https://tvposts.com/about/legal/cookie-policy provides information on how you can opt-out of cookies provided by us and our advertising partners who engage in online behavioral advertising.</p>

                        <p><strong>Opt-out of selling of your personal information or use or sharing of your information for targeted advertising.</strong> As described above, we share information with advertising partners to support our interest-based advertising, which may qualify as a “sale” or “sharing” of personal information, or “targeted advertising,” under applicable law. Where applicable laws provide such rights, such as in California and other US states with consumer privacy laws, you can opt-out of our use or sharing of personal information for these purposes by using our “[Your Privacy Choices / Do Not Sell or Share My Personal Information]”, Manage Preferences tool below, or by enabling the Global Privacy Control setting within the browser that you use to access our Service. Learn more at the Global Privacy Control website. Please note that your opt out will be specific to the device and browser you use when you opt out. We do not have actual knowledge that we have sold or shared the personal information of children under the age of 16.</p>

                        <h4>VIII. Other Sites and Services</h4>
                        <p>Our Site contains links to other websites not operated or controlled by TV Posts, including social media services. The fact that we link to a website is not an endorsement, authorization, or representation of our affiliation with that third party. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data, or solicit personal information from you. The information that you share with the Third-Party Services will be governed by the specific privacy policies and terms of service of the Third-Party Services and not by this Privacy Policy. We encourage you to read the privacy policies of the other websites and online services you use.</p>

                        <h4> IX. Security</h4>
                        <p>The security of your personal information is important to us. We employ administrative, physical, and electronic measures designed to protect your information from unauthorized access, but we cannot and do not assure that these measures will be sufficient to protect against all efforts to gain unauthorized access to the information.</p>

                        <h4>X. Age Consent</h4>
                        <p>TV Posts is intended only for users who are 13+.  If you are under the age of 18, you must have your parent's or legal guardian's permission to use the Services. Please have them read this agreement with you.</p>
                        <p>The Services are not directed to persons under 13 and we do not knowingly collect personal information from children under 13. If a parent or guardian becomes aware that his or her child has provided us with personal information without their consent, he or she should contact us at info@tvposts.com. If we become aware that a child under 13 has provided us with personal information, we will delete such information from our files.</p>

                        <h4> XI. Changes to this Privacy Policy</h4>
                        <p>This Privacy Policy may be updated from time to time for any reason; each version will apply to information collected while it was in place. We will notify you of any material changes to our Privacy Policy by posting the new Privacy Policy on our Site. Please review this Privacy Policy regularly for any changes.</p>

                        <h4>XII. Contact Us</h4>
                        <p>To exercise any of your rights in relation to your personal information processed by TV Posts, and/or if you have any questions about the handling of your personal information, please send an email to info@tvposts.com.</p>
                    </Text>
                </div>
            </div >
        </Container >
    );
};

export default PrivacyPolicy;
