
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import CustomNavLink from "./CustomNavLink";
import { incrementByAmount, decrementByAmount } from "../Redux/counterslice";
import "../App.css";

import {
  ActionIcon,
  Badge,
  Container,
  createStyles,
  Text,
  Skeleton,
  Popover,
  Divider,
  Loader,
  Table,
  NavLink,
  ScrollArea,
} from "@mantine/core";
import { Info } from "phosphor-react";
import axios from "axios";
import { useSelector } from 'react-redux';
import { TrashSimple } from "phosphor-react";
import { useDispatch } from 'react-redux';


const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    paddingBottom: "5rem",
    paddingTop: "0.5rem",
    "@media (max-width: 700px)": {
      paddingTop: "0rem",
    },
  },
  leftWrapper: {
    width: "100%",
    flex: 0.7,
    "@media (max-width: 700px)": {
      flex: 1,
    },
  },
  navLinkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover .delete-icon': {
      visibility: 'visible',
    }
  },
  deleteIcon: {
    visibility: 'hidden',
  },
}));



const Test = () => {

  const { classes } = useStyles();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const [watchListArr, setWatchListArr] = useState([]);
  const { darkmode, watchlist, setWatchlist, loading, UserInfo } = useContext(AuthContext); //Use global watchlist state so it persists across all components
  const counter = useSelector((state) => state.counter);
  console.log(counter.value);

  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (!userid) {
      console.log('No user is logged in');
      return;
    }
    async function getData() {
      await axios.get(`${process.env.REACT_APP_API_URL}/watchlist/${userid}`).then((res) => {
        console.log(res.data);
        if (res.data.tvshows) {
          setWatchListArr(res.data.tvshows);
        } else {
          setWatchListArr([]);
        }
      }).catch((e) => {
        console.log(e);
      })
    }

    getData();

  }, [counter])

  const getWatchlistClick = (tvTag) => {
    navigate(`/tv/${tvTag}`);
  };

  const dispatch = useDispatch();

  const handleDelete = async (tvTag) => {

    const payload = {
      userId: localStorage.getItem('userid'),
      tvTag: tvTag,
    };

    // Remove the show from the watchlist
    await axios.delete(`${process.env.REACT_APP_API_URL}/removefromwatchlist/${payload.userId}/${payload.tvTag}`)
      .then(() => {
        dispatch(decrementByAmount(1));
        setWatchlist(prevWatchlist => prevWatchlist.filter(show => show.tvTag !== tvTag));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  {/*Orignial tventries function, worked */ }
  const tventries = watchListArr ? watchListArr.map((show, index) => (
    <CustomNavLink
      show={show}
      index={index}
      setActive={setActive}
      getWatchlistClick={getWatchlistClick}
      handleDelete={handleDelete}
    />
  )) : [];






  return (
    <div>{!loading ? (
      <div style={{ backgroundColor: darkmode ? "#1A1B1E" : "white", color: darkmode ? "white" : "black", marginBottom: "0.5rem", borderRadius: "4px" }}>
        <div style={{ display: "flex", padding: "0.7rem 1rem 0 1rem", gap: "0.4rem", alignItems: "center", }}>
          <Text weight={700} size={12}>
            Watchlist
          </Text>{" "}

          {/*i tooltip*/}
          <Popover width={220} position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Info style={{ cursor: "pointer", }} size={15} />
            </Popover.Target>
            <Popover.Dropdown>
              <Text size={"xs"}>
                Watchlist is shown in the order a show was added.
              </Text>
              <Divider my="xs" />
              <Text color={"#1DA1F2"} size={"sm"} weight={300}>
                Upgrade to TV Pro for advanced features
              </Text>
            </Popover.Dropdown>
          </Popover>
        </div>
        <ScrollArea h={300} type="auto" scrollbarSize={20} offsetScrollbars>
          {tventries}
        </ScrollArea>
        {/* Commenting out view all hypherlink */}
        {/*
        <Text style={{ cursor: "pointer", padding: "0.7rem 1rem 0.7rem 1.2rem", }} onClick={() => { navigate("/Leaderboard"); }} size={"12px"}>
          {" "}
          View all
        </Text>
      */}
      </div>
    )
      : (
        <div style={{ paddingBottom: "0.7rem", }} className={classes.accounts}>

          {new Array(4).fill(0).map((_, index) => {

            return (

              <div style={{ display: "flex", alignItems: "center", }} key={index} className={classes.account}>
                <Skeleton style={{ width: "40px", height: "40px", borderRadius: "50%", }} />

                <div style={{ display: "flex", flex: 1, flexDirection: "column", }}>
                  <Skeleton width={"100px"} height={8} radius="xl" />
                  <Skeleton width={"60px"} height={8} mt={10} radius="xl" />
                </div>
              </div>
            );
          })}
        </div>

      )}</div>

  );

};
export default Test;