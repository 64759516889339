import './MentionList.css'

import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState,
} from 'react'

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index]
    {/*}
    if (item) {
      const modifiedTvTag = item.tvTag.substring(1); //removes "~" when user clicks suggested TvShow in metion/suggestion list in modal
      props.command({ id: modifiedTvTag })
    }
  */} {/*}
    if (item) {
      const tvTag = item.tvTag.substring(1);
      props.command({ id: tvTag });
      console.log("IIIIIIITTTTTEEEEEMMMMMM", item)
    } */}

  if (item) {
    let id;
    if (item.tvTag) {
      id = item.tvTag.substring(1); // If it's a tvTag, remove the tilde
    } else if (item.username) {
      id = item.username; // If it's a username, use it as is
    }
  
    if (id) {
      props.command({ id });
    }
  
    console.log("IIIIIIITTTTTEEEEEMMMMMM", item)
  }
}
  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="items">
      {props.items.length
        ? props.items.map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            key={index}
            onClick={() => selectItem(index)}
          >
          {/*   {item.tvTag ? item.tvTag : item.username} */}
          {item.avatar && <img className="avatar"src={item.avatar} alt={item.username} style={{ marginRight: '10px' }} />}
          {item.tvTag ? item.tvTag : item.username}
          </button>
        ))
        : <div className="item">No result</div>
      }
    </div>
  )
})

/*This is a React component named MentionList that displays a list of items and allows the 
user to select an item using the arrow keys or the mouse.*/