import { React, useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import dollarSignSuggestions from './tags-extensions/DollarSignSuggestions'
import AtSignSuggestions from './tags-extensions/AtSignSuggestions'
import Mention from '@tiptap/extension-mention'
import { PluginKey } from '@tiptap/pm/state';
import Hashtag from './tags-extensions/Hashtag.js'
import HashtagMark from './tags-extensions/HashtagMark.js'
import CharacterCount from '@tiptap/extension-character-count'
import Link from '@tiptap/extension-link';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import { useParams } from 'react-router-dom';
import cleanHTML from './tags-extensions/cleanHTML';

const Tiptap = ({ tvTag, autoFocus, runEffect, onChange = () => { } }) => {
  tvTag = tvTag || null;
  console.log("Initial tvTaaaaaaaaaaaaaaaaaaaaag:", tvTag);
  const tvtagWithoutTilde = tvTag ? tvTag.replace('~', '') : null;
  console.log("tvtagWithoutTildeeeeeeeeeeeeeeeeee: ", tvtagWithoutTilde);
  console.log("Tiptap tvtag: ", tvTag);

  const characterLimit = 280

  const DollarSignMentionPluginKey = new PluginKey('dollarSignMention');
  const AtSignMentionPluginKey = new PluginKey('atSignMention');


  const DollarSign = Mention.extend({
    name: 'dollarSignMention',
    addOptions() {
      return {
        HTMLAttributes: {},
        renderLabel({ options, node }) {
          var _a;
          return `${options.suggestion.char}${(_a = node.attrs.label) !== null && _a !== void 0 ? _a : node.attrs.id}`;
        },
        suggestion: {
          char: '~',
          pluginKey: DollarSignMentionPluginKey,
          command: ({ editor, range, props }) => {
            console.log("Where are the props, whaer are the props", props);
            var _a, _b;
            // increase range.to by one when the next node is of type "text"
            // and starts with a space character
            const nodeAfter = editor.view.state.selection.$to.nodeAfter;
            const overrideSpace = (_a = nodeAfter === null || nodeAfter === void 0 ? void 0 : nodeAfter.text) === null || _a === void 0 ? void 0 : _a.startsWith(' ');
            if (overrideSpace) {
              range.to += 1;
            }
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: this.name,
                  attrs: props,
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ])
              .run();
            (_b = window.getSelection()) === null || _b === void 0 ? void 0 : _b.collapseToEnd();
          },
          allow: ({ state, range }) => {
            const $from = state.doc.resolve(range.from);
            const type = state.schema.nodes[this.name];
            const allow = !!$from.parent.type.contentMatch.matchType(type);
            return allow;
          },
        },
      };
    },
  })

  const atSign = Mention.extend({
    name: 'atSignMention',
    addOptions() {
      return {
        HTMLAttributes: {},
        renderLabel({ options, node }) {
          var _a;
          return `${options.suggestion.char}${(_a = node.attrs.label) !== null && _a !== void 0 ? _a : node.attrs.id}`;
        },
        suggestion: {
          char: '@',
          pluginKey: AtSignMentionPluginKey,
          command: ({ editor, range, props }) => {
            var _a, _b;
            // increase range.to by one when the next node is of type "text"
            // and starts with a space character
            const nodeAfter = editor.view.state.selection.$to.nodeAfter;
            const overrideSpace = (_a = nodeAfter === null || nodeAfter === void 0 ? void 0 : nodeAfter.text) === null || _a === void 0 ? void 0 : _a.startsWith(' ');
            if (overrideSpace) {
              range.to += 1;
            }
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: this.name,
                  attrs: props,
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ])
              .run();
            (_b = window.getSelection()) === null || _b === void 0 ? void 0 : _b.collapseToEnd();
          },
          allow: ({ state, range }) => {
            const $from = state.doc.resolve(range.from);
            const type = state.schema.nodes[this.name];
            const allow = !!$from.parent.type.contentMatch.matchType(type);
            return allow;
          },
        },
      };
    },
  })


  const editor = useEditor({
    extensions: [
      Hashtag,
      HashtagMark,
      CharacterCount.configure({
        limit: characterLimit,
      }),
      StarterKit,
      Emoji.configure({
        emojis: gitHubEmojis,
      }),
      Placeholder.configure({
        placeholder: 'Share your thoughts...',
        emptyEditorClass: 'is-editor-empty',
      }),
      Color.configure({
        types: ['textStyle'],
      }),
      TextStyle,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: AtSignSuggestions,
      }),
      DollarSign.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: dollarSignSuggestions
      }),
      atSign.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: AtSignSuggestions
      }),
      Link.configure({ 
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {
          class: 'mention',
        }}),
    ],

    onFocus({ editor, event, props }) {
      console.log(`onFocus ran with editor: ${editor} and event: ${event}`);
      console.log('ONFOCUUUUUUUUUUUUUUUUUUUUUUS:', editor, event);

      if (runEffect && editor.getHTML() === "<p></p>") {
        console.log("I LOOOOOOOVVVVVVVVEEEEEEEE SLIDERS!!!!!!!!");
        editor.commands.insertContent(`<p><span data-type="dollarSignMention" data-id="${tvtagWithoutTilde}"></span><span style="color: #FFFFFF"> </span></p>`);
        // editor.chain().focus().unsetColor().run();
      }

      if (!runEffect && editor.getHTML() === "<p></p>") {


        editor.commands.insertContent(``)

      }
    },


    content: '',


    onUpdate: ({ editor }) => {
      let newContent = editor.getHTML();
      newContent = cleanHTML(newContent); // sanitize the HTML content
      console.log("New content: ", newContent);
      onChange(newContent);
    },
  });

  //Add useffect for autofocus

  useEffect(() => {
    console.log('useEfffeeeeeccccccctttttttttt babbbyyy!!!');
    if (autoFocus) {
      console.log(`useEffect ran with autoFocus: ${autoFocus} and runEffect: ${runEffect}`);
      const checkEditor = setInterval(() => {
        if (editor) {

          console.log('editor is defined', editor);
          // Focus the editor
          editor.chain().focus().unsetColor().run();
          // editor.commands.setTextSelection(0);
          // Insert the tag and cursor
          // editor.commands.insertContent('');

          clearInterval(checkEditor);
        }
      }, 100);
    }
  }, [editor, runEffect]);



  // const remainingCharacters = 280 + tvtagWithoutTilde.length - (editor?.storage.characterCount.characters() || 0);
  return (
    <div >
      <EditorContent className="editor-content" editor={editor} />
      <div className="character-count__text">
        {characterLimit - editor?.storage.characterCount.characters() - (tvtagWithoutTilde ? tvtagWithoutTilde.length : 0)} characters
      </div>
    </div>
  );

};

export default Tiptap;
