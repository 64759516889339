import { useContext, useState, useEffect } from "react";
import {
  Modal,
  Textarea,
  Group,
  Divider,
  Button,
  Text,
  Input,
  Select,
  Mark,
} from "@mantine/core";
import {
  Alarm,
  ChartBarHorizontal,
  CircleWavyCheck,
  Gif,
  ImageSquare,
  Lightning,
  X,
  XCircle,
} from "phosphor-react";
import { AddNewPost, AddNewPostpoll } from "../api/POST";
import { showNotification } from "@mantine/notifications";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import locale from "date-fns/locale/en-US";
import { AuthContext } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import GifPicker from "gif-picker-react";
import Tiptap from './Tiptap';
import axios from 'axios';
import './tags-extensions/Tiptap.css';


export default function CreatePostModal({
  opened,
  setOpened,
  UserInfo,
  quotepostinfo,
  ShowImdbid,
  tvTag,
  setTvShowPosts,
  runEffect,
}) {
  const formatDistanceLocale = {
    lessThanXSeconds: "{{count}}s",
    xSeconds: "{{count}}s",
    halfAMinute: "30s",
    lessThanXMinutes: "{{count}}m",
    xMinutes: "{{count}}m",
    aboutXHours: "{{count}}h",
    xHours: "{{count}}h",
    xDays: "{{count}}d",
    aboutXWeeks: "{{count}}w",
    xWeeks: "{{count}}w",
    aboutXMonths: "{{count}}mo",
    xMonths: "{{count}}mo",
    aboutXYears: "{{count}}y",
    xYears: "{{count}}y",
    overXYears: "{{count}}y",
    almostXYears: "{{count}}y",
  };
  function formatDistance(token, count, options) {
    options = options || {};

    const result = formatDistanceLocale[token].replace("{{count}}", count);

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return "in " + result;
      } else {
        return result + " ago";
      }
    }

    return result;
  }
  const [flieInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [text, settext] = useState("");
  const [loading, setloading] = useState(false);
  const [filetype, setfiletype] = useState("");
  const [media, setmedia] = useState(null);
  const [poll, setpoll] = useState(false);
  const [choice1, setchoice1] = useState("");
  const [choice2, setchoice2] = useState("");
  const [choice3, setchoice3] = useState("");
  const [choice4, setchoice4] = useState("");
  const [polldays, setpolldays] = useState("1");
  const [pollhours, setpollhours] = useState("0");
  const [pollminutes, setpollminutes] = useState("0");
  const [pollquestion, setpollquestion] = useState("");
  const [gifstatus, setgifstatus] = useState(false);
  const [gifpreview, setgifpreview] = useState("");
  const [tvtag, setTvtag] = useState("");
  const imgsizelimit = 9437184; //9mb
  const videosizelimit = 52428800; //50 mb
  const { darkmode } = useContext(AuthContext);

  useEffect(() => {
    if (opened) {
      console.log('Modal is opened. Here are the props:');
      console.log('UserInfo:', UserInfo);
      console.log('ShowImdbid:', ShowImdbid);
      console.log('tvTag:', tvTag);
      console.log('runEffect:', runEffect);
    }
  }, [opened]);

  useEffect(() => {
    if (!runEffect) return;
    console.log(`useEffect ran with opened: ${opened} and runEffect: ${runEffect}`); 
    console.log(`TvTag in CreatePostModal GET: ${tvTag}`); //Replace with possibly tvTag
    if (runEffect && opened && ShowImdbid !== undefined && ShowImdbid!==null) {
      async function fetchTvTag() {
        await axios.get(`${process.env.REACT_APP_API_URL}/tvtag/${ShowImdbid}`).then((res) => {
          console.log("This means the useEffect ran again", res.data);
          if (res.status === 200) {
            setTvtag(res.data);
          } else {
            setTvtag("NoTVTagFound");
          }
        }).catch((error) => {
          console.error('There was an error with the tvtag!', error);
          setTvtag("SomeRandomProblemwithTVTag");
        })
      }
      fetchTvTag();
    }
  }, [opened, runEffect]);

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setPreviewSource(reader.result); //IMAGE PREVIEW
    };
  };
  const handleflieInputChange = (e) => {
    setError("");
    setPreviewSource("");
    setfiletype("");
    const file = e.target.files[0];
    if (file.type.match("image.*")) {
      if (file.size > imgsizelimit) {
        setError("Image size is too big. Max allowed size is 9MB");
      } else {
        setfiletype("image");
        previewFile(file);
        setmedia(file);
      }
    }
    if (file.type.match("video.*")) {
      if (file.size > videosizelimit) {
        setError("Video size is too big. Max allowed size is 95MB");
      } else {
        setfiletype("video");
        previewFile(file);
        setmedia(file);
      }
    }
  };

  const closemodal = () => {
    setOpened(false);
    setPreviewSource("");
    settext("");
    setloading(false);
    setError("");
    setFileInputState("");
    setfiletype("");
    setmedia(null);
    setgifstatus(false);
    setgifpreview("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setloading(true);
    setError("");

    
    const formData = new FormData();
    formData.append("media", media);
    formData.append("text", text);
    formData.append("quoteid", quotepostinfo?.id ? quotepostinfo?.id : "");
    formData.append("gif", gifpreview);
    formData.append("tvtag", tvtag); // add tvtag to post data
    formData.append("imdbid", ShowImdbid); // add imdbid to post data

    await AddNewPost(formData)
      .then((res) => {
        const newPost = res.data.newPost;
      // console.log("Update the FFFEEEEDDDD", newPost);
        setTvShowPosts((prevPosts) => {
          return [newPost, ...prevPosts];
        });
        closemodal();



        // navigate(`/post/${res.data.newpostid}`);

        {/*  navigate(`/tv/${tvtag}`); */ }

        showNotification({
          color: "teal",
          icon: <Lightning size={18} />,
          title: "Post Created Successfully",
          autoClose: 3000,
        });


      })
      .catch((err) => {
        setloading(false);
        if (err.response.status === 0) {
          setError("Internal Server Error");
        } else {
          setError(err.response.data);
        }
      });
  };

  return (
    <>
      {UserInfo && (
      <Modal
        zIndex={1000}
        padding={0}
        opened={opened}
        onClose={() => closemodal()}
        withCloseButton={false}
      >
      {/* Note: X button moved to the right side */}
      <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '1rem 2rem 0 0',
        }}>
        <X
          onClick={() => closemodal()}
          style={{
            padding: "1rem 0rem 0rem 1rem",
          }}
          size={40}
        />
      </div>
        {error && (
          <div style={{ padding: "1rem 0rem 0rem 1rem" }}>
            <Text size={"sm"} color={"red"}>
              {" "}
              {error}
            </Text>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div
            style={{
              padding: "1rem",
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <img
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              src={UserInfo?.avatar}
              alt=""
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {/* text preview */}
              <Tiptap
                runEffect={runEffect} 
                tvTag={tvTag}  
                autoFocus={true} 
                onChange={(newContent) => settext(newContent)} 
                />
              {/* New GIF preview div */}
              {gifpreview && (
                <div style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  position: "relative",
                  display: "inline-block",
                }}>
                  <span
                    onClick={() => {
                      setgifpreview("");
                    }}
                    style={{
                      position: "absolute",
                      left: "5px",
                      top: "14px",
                      cursor: "pointer",
                    }}
                  >
                    <XCircle size={25} />
                  </span>
                  <img
                    style={{ maxWidth: "300px", maxHeight: "300px", width: "100%", height: "auto" }}
                    src={gifpreview}
                    alt="Selected GIF"
                  />
                </div>
              )}
              {/* image preview */}
              {previewSource && (
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",

                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  {filetype !== "video" && (
                    <span
                      onClick={() => {
                        setFileInputState("");
                        setfiletype("");
                        setPreviewSource("");
                        setmedia(null);
                      }}
                      style={{
                        position: "absolute",
                        left: "5px",
                        top: "14px",
                        cursor: "pointer",
                      }}
                    >
                      <XCircle size={25} />
                    </span>
                  )}

                  {previewSource && filetype === "image" ? (
                    <img
                      style={{ width: "100%", height: "auto" }}
                      src={previewSource}
                      alt=""
                    />
                  ) : (
                    <>
                      {filetype === "video" && previewSource && (
                        <span
                          onClick={() => {
                            setFileInputState("");
                            setfiletype("");
                            setPreviewSource("");
                            setmedia(null);
                          }}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <XCircle size={25} />
                        </span>
                      )}
                      <video
                        style={{ width: "200px", height: "200px" }}
                        controls
                      >
                        <source src={previewSource} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </>
                  )}
                </div>
              )}
              {/* gif preview */}
              {gifstatus && (
                <div
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",

                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  {gifpreview && (
                    <span
                      onClick={() => {
                        setgifpreview("");
                      }}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        left: "5px",
                        top: "14px",
                      }}
                    >
                      <XCircle size={25} />
                    </span>
                  )}

                  {gifpreview && (
                    <img
                      style={{ maxWidth: "300px", maxHeight: "300px", width: "100%", height: "auto" }}
                      src={gifpreview}
                      alt=""
                    />
                  )}
                </div>
              )}
              {quotepostinfo && (
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: "0.9rem",

                    display: "flex",
                    flexDirection: "column",

                    paddingBottom:
                      !quotepostinfo.image && !quotepostinfo.gif
                        ? "0.7rem"
                        : "0",
                    gap: "0.5rem",
                    borderRadius: "0.5rem",

                    border: darkmode
                      ? "1px solid #2f3336"
                      : "1px solid #e6ecf0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.3rem",
                      alignItems: "center",
                      padding: "0.7rem 0.7rem 0 0.7rem",
                    }}
                  >
                    <img
                      style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "50%",
                      }}
                      src={quotepostinfo?.user?.avatar}
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "0.2rem",
                        alignItems: "center",
                      }}
                    >
                      <Text size="15px" weight={500}>
                        {quotepostinfo?.user?.username}
                      </Text>
                      {quotepostinfo?.user.verified &&
                        (quotepostinfo?.user.id !== 5 ? (
                          <CircleWavyCheck
                            size={17}
                            color="#0ba6da"
                            weight="fill"
                          />
                        ) : (
                          <CircleWavyCheck
                            size={17}
                            color="#0ba6da"
                            weight="fill"
                          />
                        ))}
                    </div>
                    <Text color={"dimmed"}>·</Text>
                    <Text color={"dimmed"}>
                      {" "}
                      {formatDistanceToNowStrict(
                        new Date(quotepostinfo?.createdAt),
                        {
                          locale: {
                            ...locale,
                            formatDistance,
                          },
                        }
                      )}
                    </Text>
                  </div>
                  {quotepostinfo?.text && (
                    <Text
                      size="15px"
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "pre-wrap",
                        padding: "0 0.7rem 0 0.7rem",
                      }}
                    >
                      {quotepostinfo?.text}
                    </Text>
                  )}

                  {quotepostinfo?.image && (
                    <>
                      {quotepostinfo?.filetype === "image" ? (
                        <img
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "0 0 0.5rem 0.5rem",
                          }}
                          loading="lazy"
                          src={quotepostinfo?.image}
                          alt=""
                        />
                      ) : (
                        <video
                          poster={quotepostinfo?.image.slice(0, -3) + "jpg"}
                          // preload="none"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "0 0 0.5rem 0.5rem",
                          }}
                          controls
                        >
                          <source src={quotepostinfo?.image} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </>
                  )}
                  {quotepostinfo?.gif && (
                    <>
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "0 0 0.5rem 0.5rem",
                        }}
                        loading="lazy"
                        src={quotepostinfo?.gif}
                        alt=""
                      />
                    </>
                  )}
                </div>
              )}
              <Divider my="xs" color={darkmode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"}
              />

              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {gifstatus ? (
                    <ImageSquare size={23} color={"gray"} />
                  ) : (
                    <div className="upload-btn-wrapper">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <ImageSquare size={23} color={"#0d61e7"} />
                      </div>
                      <input
                        value={flieInputState}
                        accept="image/* video/*"
                        type="file"
                        onChange={handleflieInputChange}
                      />
                    </div>
                  )}

                  <Gif
                    onClick={() => {
                      if (
                        !quotepostinfo &&
                        !previewSource &&
                        !gifstatus
                      ) {
                        setgifstatus(true);
                        setgifpreview("");
                      }
                      if (gifstatus) {
                        setgifstatus(false);
                        setgifpreview("");
                      }
                    }}
                    weight="fill"
                    style={{
                      cursor: "pointer",
                    }}
                    size={23}
                    color={
                      !poll && !quotepostinfo && !previewSource && !gifstatus
                        ? "#0d61e7"
                        : "gray"
                    }
                  />
                </div>

                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {!loading ? (
                    <Button
                      type="submit"
                      radius={"xl"}
                      size="xs"
                    >
                      Post
                    </Button>
                  ) : (
                    <Button
                      loading={loading}
                      type="submit"
                      radius={"xl"}
                      size="xs"
                    >
                      Posting
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        {gifstatus && (
          <div
            style={{
              padding: "0.5rem 3.8rem",
            }}
          >
            <GifPicker
              onGifClick={(item) => {
                setgifpreview(item.url);
                setgifstatus(false);
              }}
              tenorApiKey={"AIzaSyDYRiFFrO4brfbUE9dMkL7zGC3GrJdIGqA"}
            />
          </div>
        )}
      </Modal>
    )}
      <Group position="center"></Group>
    
    </>
  );
}
