import React, { useState, useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import styled from '@emotion/styled';
import { AuthContext } from '../context/Auth';

const FormContainer = styled.form`
  background-color: ${(props) => (props.darkmode ? '#121212' : '#ffffff')};
  color: ${(props) => (props.darkmode ? '#ffffff' : '#000000')};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.darkmode ? '#333' : '#ccc')};
  background-color: ${(props) => (props.darkmode ? '#1e1e1e' : '#ffffff')};
  color: ${(props) => (props.darkmode ? '#ffffff' : '#000000')};
  ::placeholder {
    color: ${(props) => (props.darkmode ? '#bfbfbf' : '#a3a3a3')};
  }
`;

const CardElementContainer = styled.div`
  margin-bottom: 20px;
  background-color: ${(props) => (props.darkmode ? '#1e1e1e' : '#f9f9f9')};
  padding: 10px;
  border-radius: 5px;
`;

const Button = styled.button`
  background-color: #03D3F9;
  color: ${(props) => (props.darkmode ? '#fff' : '#000')};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: ${(props) => (props.darkmode ? '#555' : '#ddd')};
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const ToggleButton = styled.button`
  background-color: ${(props) => (props.darkmode ? '#333' : '#f0f0f0')};
  color: ${(props) => (props.darkmode ? '#fff' : '#000')};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const { darkmode } = useContext(AuthContext);
    const [showAddress, setShowAddress] = useState(false);
    const [sameAsBilling, setSameAsBilling] = useState(true);

    const handleToggleAddress = () => {
        setShowAddress((prev) => !prev);
    };

    const handleCheckboxChange = (event) => {
        setSameAsBilling(event.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setErrorMessage(error.message);
        } else {
            const { id } = paymentMethod;
            try {
                const response = await axios.post('/api/charge', { id, amount: 2000 }); // Amount in cents
                console.log(response.data);
                alert('Payment Successful!');
            } catch (error) {
                setErrorMessage('Payment failed');
            }
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit} darkmode={darkmode}>
            <ToggleButton type="button" onClick={handleToggleAddress} darkmode={darkmode}>
                {showAddress ? 'Hide Address Fields' : 'Show Billing and Shipping Fields'}
            </ToggleButton>
            {showAddress && (
                <>
                    <InputContainer>
                        <Input type="text" placeholder="First Name" darkmode={darkmode} />
                        <Input type="text" placeholder="Last Name" darkmode={darkmode} />
                        <Input type="text" placeholder="Billing Address Line 1" darkmode={darkmode} />
                        <Input type="text" placeholder="Billing Address Line 2" darkmode={darkmode} />
                        <Input type="text" placeholder="Billing City" darkmode={darkmode} />
                        <Input type="text" placeholder="Billing State" darkmode={darkmode} />
                        <Input type="text" placeholder="Billing Zip Code" darkmode={darkmode} />
                    </InputContainer>

                    <CheckboxContainer>
                        <Checkbox
                            type="checkbox"
                            checked={sameAsBilling}
                            onChange={handleCheckboxChange}
                        />
                        <label>Shipping Address is the same as the Billing Address</label>
                    </CheckboxContainer>

                    {!sameAsBilling && (
                        <InputContainer>
                            <Input type="text" placeholder="Shipping Address Line 1" darkmode={darkmode} />
                            <Input type="text" placeholder="Shipping Address Line 2" darkmode={darkmode} />
                            <Input type="text" placeholder="Shipping City" darkmode={darkmode} />
                            <Input type="text" placeholder="Shipping State" darkmode={darkmode} />
                            <Input type="text" placeholder="Shipping Zip Code" darkmode={darkmode} />
                        </InputContainer>
                    )}
                </>
            )}
            <CardElementContainer darkmode={darkmode}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                color: darkmode ? '#ffffff' : '#000000',
                                backgroundColor: darkmode ? '#1e1e1e' : '#f9f9f9',
                                '::placeholder': {
                                    color: darkmode ? '#bfbfbf' : '#a3a3a3',
                                },
                            },
                        },
                    }}
                />
            </CardElementContainer>
            <ButtonContainer>
                <Button type="submit" disabled={!stripe} darkmode={darkmode}>
                    Buy Now
                </Button>
            </ButtonContainer>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </FormContainer>
    );
};

export default CheckoutForm;
