import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActionIcon,
  Avatar,
  Button,
  Container,
  createStyles,
  Indicator,
  Loader,
  ScrollArea,
  Text,
  Textarea,
} from "@mantine/core";
import { ArrowLeft, PaperPlaneRight, WarningCircle } from "phosphor-react";
import { getchatmessages } from "../../api/GET";
import { sendmessage } from "../../api/POST";
import { Sidebar } from "../../Components/Sidebar";
import { AuthContext } from "../../context/Auth";
import { format } from "date-fns";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    paddingBottom: "5rem",
    paddingTop: "0.5rem",
    "@media (max-width: 700px)": {
      paddingTop: "0rem",
    },
  },
  leftWrapper: {
    flex: 0.7,
    "@media (max-width: 700px)": {
      flex: 1,
    },
  },
}));

export const Chat = ({ socket }) => {
  const { classes } = useStyles();
  const { darkmode, UserInfo, onlineusers } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const viewport = useRef(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [chatInfo, setChatInfo] = useState();
  const [msgCount, setMsgCount] = useState(0);
  const { roomid } = useParams();
  const [scrollDown, setScrollDown] = useState(false);
  const [newMsgCount, setNewMsgCount] = useState(0);
  const messagesEndRef = useRef(null);
  const isMounted = useRef(true);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!UserInfo) {
      navigate("/");
    }
  }, [UserInfo, navigate]);

  useEffect(() => {
    socket.emit("joinroom", { roomid });

    return () => {
      socket.emit("leaveroom", { roomid });
    };
  }, [roomid, socket]);

  useEffect(() => {
    isMounted.current = true;
    async function getMessages() {
      try {
        const res = await getchatmessages(roomid, 0);
        if (isMounted.current) {
          setMessages(res.data.chatmessages.chats.reverse());
          setScrollDown(true);
          setChatInfo(res.data.chatmessages);
          setLoading(false);
          setMsgCount(res.data.chatmsgcount);
        }
      } catch (error) {
        if (isMounted.current) {
          navigate("/");
        }
      }
    }

    getMessages();

    return () => {
      isMounted.current = false;
    };
  }, [roomid, navigate]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    const handleNewMessage = (message) => {
      setMessages((prevMessages) => {
        if (!prevMessages.find((msg) => msg.id === message.id)) {
          return [...prevMessages, message];
        }
        return prevMessages;
      });
      setNewMsgCount((prev) => prev + 1);
    };

    socket.on("newmessage", handleNewMessage);

    return () => {
      socket.off("newmessage", handleNewMessage);
    };
  }, [socket]);

  const handleSendMessage = async () => {
    if (text.trim() === "") return;
    
    try {
      const newMessage = {
        id: Date.now(), // Temporary ID
        message: text,
        user: { username: UserInfo.username },
        createdAt: new Date().toISOString()
      };
      
      setText("");
      
      await sendmessage(chatInfo?.id, text);
    } catch (err) {
      showNotification({
        icon: <WarningCircle size={18} />,
        color: "red",
        title: err.response ? err.response.data : "Error sending message",
        autoClose: 4000,
      });
    }
  };

  const fetchMoreData = async () => {
    setPage((prevPage) => prevPage + 1);
    try {
      const res = await getchatmessages(roomid, page + 1);
      setMessages((prev) => [...res.data.chatmessages.chats.reverse(), ...prev]);
      setChatInfo(res.data.chatmessages);
      setMsgCount(res.data.chatmsgcount);
    } catch (error) {
      navigate("/");
    }
  };

  return (
    <Container px={0} className={classes.wrapper}>
      <div className={classes.leftWrapper}>
        <div
          style={{
            backgroundColor: darkmode ? "#1A1B1E" : "white",
            color: darkmode ? "white" : "black",
            padding: "1rem 0rem 1rem 1rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <ActionIcon onClick={() => navigate(-1)}>
            <ArrowLeft size="20px" />
          </ActionIcon>
          <div
            onClick={() =>
              navigate(
                `/${
                  chatInfo?.userone?.username === UserInfo?.username
                    ? chatInfo?.usertwo?.username
                    : chatInfo?.userone?.username
                }`
              )
            }
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              cursor: "pointer",
            }}
          >
            {chatInfo && (
              <Indicator
                disabled={
                  !onlineusers.includes(
                    chatInfo?.userone?.username === UserInfo?.username
                      ? chatInfo?.usertwo?.id
                      : chatInfo?.userone?.id
                  )
                }
                style={{ cursor: "pointer" }}
                withBorder
                inline
                color="green"
                size={9}
                offset={6}
                position="bottom-end"
              >
                <Avatar
                  size="30px"
                  radius={"xl"}
                  src={
                    chatInfo?.userone?.username === UserInfo?.username
                      ? chatInfo?.usertwo?.avatar
                      : chatInfo?.userone?.avatar
                  }
                  alt=""
                  loading="lazy"
                />
              </Indicator>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text weight={500}>
                {chatInfo?.userone?.username === UserInfo?.username
                  ? chatInfo?.usertwo?.username
                  : chatInfo?.userone?.username}
              </Text>
              {onlineusers.includes(
                chatInfo?.userone?.username === UserInfo?.username
                  ? chatInfo?.usertwo?.id
                  : chatInfo?.userone?.id
              ) && (
                <Text color={"dimmed"} size={10}>
                  Active now
                </Text>
              )}
            </div>
          </div>
        </div>
        <ScrollArea
          viewportRef={viewport}
          style={{
            height: "50vh",
            backgroundColor: darkmode ? "#1A1B1E" : "white",
            color: darkmode ? "white" : "black",
            borderTop: darkmode ? "1px solid #2F3336" : "1px solid #E5E5E5",
            padding: "0.5rem 1.5rem 0rem 1.5rem",
          }}
        >
          {msgCount + newMsgCount > messages.length && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0.5rem 0rem" }}>
              <Button onClick={fetchMoreData} color="gray" variant="subtle">
                Load older messages
              </Button>
            </div>
          )}
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Loader />
            </div>
          ) : (
            messages.map((message) => (
              <div key={message.id}>
                {message?.user?.username !== UserInfo?.username ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      marginBottom: "1.5rem",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundColor: darkmode
                            ? "rgb(47, 51, 54)"
                            : "rgb(239, 243, 244)",
                          padding: "0.5rem",
                          borderRadius: "8px 8px 8px 0px",
                          wordBreak: "break-all",
                          width: "fit-content",
                        }}
                      >
                        <Text
                          align="left"
                          color={darkmode ? "white" : "#0F1419"}
                          size={14}
                        >
                          {message?.message}
                        </Text>
                      </div>
                      <Text pt={5} size={12} color={"dimmed"}>
                        {format(
                          new Date(message?.createdAt),
                          "MMM d yyyy"
                        ) === format(new Date(), "MMM d yyyy")
                          ? format(new Date(message?.createdAt), "h:mm a")
                          : format(
                              new Date(message?.createdAt),
                              "MMM d yyyy h:mm a"
                            )}
                      </Text>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      marginBottom: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        gap: "0.4rem",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgb(29, 155, 240)",
                          padding: "0.5rem",
                          borderRadius: "8px 8px 0px 8px",
                          wordBreak: "break-all",
                          width: "fit-content",
                        }}
                      >
                        <Text align="left" color="white" size={14}>
                          {message?.message}
                        </Text>
                      </div>
                      <Text align="right" size={12} color={"dimmed"}>
                        {format(
                          new Date(message?.createdAt),
                          "MMM d yyyy"
                        ) === format(new Date(), "MMM d yyyy")
                          ? format(new Date(message?.createdAt), "h:mm a")
                          : format(
                              new Date(message?.createdAt),
                              "MMM d yyyy h:mm a"
                            )}
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </ScrollArea>

        <div
          style={{
            padding: "1.5rem 1rem 1rem 1rem",
            backgroundColor: darkmode ? "#1A1B1E" : "white",
            borderTop: darkmode ? "1px solid #2F3336" : "1px solid #E5E5E5",
          }}
        >
          <Textarea
            autosize
            minRows={1}
            maxRows={3}
            maxLength={500}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            variant="filled"
            placeholder="Send a message"
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "0.8rem",
              paddingTop: "0.8rem",
            }}
          >
            <Text size={12} color={darkmode ? "white" : "black"}>
              {text.length} / 500
            </Text>
            <ActionIcon
              color="dark"
              variant="transparent"
              onClick={handleSendMessage}
              disabled={text.trim().length === 0}
            >
              <PaperPlaneRight size={20} />
            </ActionIcon>
          </div>
        </div>
      </div>

      <Sidebar />
    </Container>
  );
};
