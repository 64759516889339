import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import axios from 'axios';

import MentionList from './MentionList.jsx'

const dollarSignSuggestions = {
  items: async ({ query }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/gettvtag?search=${query}`);
      const data = res.data;
      console.log("DollarSignSuggestions data!!!!!: ", data);
      return data;
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}

export default dollarSignSuggestions;

/*This code is defining a configuration object for the Mention extension in tiptap, a headless rich - text editor for web.This configuration object is specifically designed to provide suggestions when the user types a dollar sign($).  Here's a breakdown of what the code does:
items: This function is called whenever the user types something after the $.It receives the typed query as an argument and returns an array of suggestions.In this case, it's returning a list of stock symbols ('TSLA', 'GOOG', 'APPL') that start with the query, limited to the first 5 matches.
render: This function returns an object with several methods that control the lifecycle of the suggestion popup:
onStart: This method is called when the mention starts(i.e., when the user types $).It creates a new React component using the MentionList component and the provided props, and then creates a new Tippy.js tooltip(the suggestion popup) attached to the body of the document.The tooltip is configured to be positioned at the location of the typed $ and to contain the MentionList component.
onUpdate: This method is called whenever the mention is updated(i.e., when the user types or deletes something after the $).It updates the props of the MentionList component and repositions the tooltip at the location of the typed $.
onKeyDown: This method is called when the user presses a key while the mention is active.If the user presses the Escape key, it hides the tooltip.Otherwise, it delegates the key handling to the MentionList component.
onExit: This method is called when the mention ends(i.e., when the user selects a suggestion or moves the cursor away from the $).It destroys the tooltip and the MentionList component.*/


