import React, { useContext, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import styled from '@emotion/styled';
import CheckoutForm from './CheckoutForm';
import { AuthContext } from '../context/Auth';

// Make sure to replace with your own publishable key
const stripePromise = loadStripe('pk_test_your_publishable_key');

const Container = styled.div`
  background-color: ${(props) => (props.darkmode ? '#121212' : '#ffffff')};
  color: ${(props) => (props.darkmode ? '#ffffff' : '#000000')};
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  button {
    background-color: ${(props) => (props.darkmode ? '#333' : '#f0f0f0')};
    color: ${(props) => (props.darkmode ? '#fff' : '#000')};
    border: none;
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
      background-color: ${(props) => (props.darkmode ? '#555' : '#ddd')};
      cursor: not-allowed;
    }
  }

  span {
    font-size: 18px;
    margin: 0 10px;
  }
`;

const ProductPage = () => {
  const { darkmode } = useContext(AuthContext);
  const [quantity, setQuantity] = useState(1);
  const pricePerItem = 9.10;
  const totalPrice = (pricePerItem * quantity).toFixed(2);

  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  return (
    <Elements stripe={stripePromise}>
      <Container darkmode={darkmode}>
        <h1 style={{ textAlign: 'center' }}>Homelander Card</h1>
        <img src={`${process.env.PUBLIC_URL}/HomelanderCard.png`} alt="Homelander Card" style={{ width: '100%', marginBottom: '20px' }} />
        <h2>The Boys, Superhero, Funny Sarcastic Superior Evil Vought Dark Comedy Netflix Amazon Prime Black Noir, Starlight, Hughie</h2>
        <QuantityContainer darkmode={darkmode}>
          <button onClick={decrementQuantity} disabled={quantity <= 1}>-</button>
          <span>{quantity}</span>
          <button onClick={incrementQuantity}>+</button>
        </QuantityContainer>
        <p style={{ textAlign: 'center' }}>Total Price: ${totalPrice}</p>
        <CheckoutForm />
      </Container>
    </Elements>
  );
};

export default ProductPage;



