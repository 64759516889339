import { Mark, mergeAttributes } from '@tiptap/core';

const HashtagMark = Mark.create({
  name: 'hashtagMark',

  // Define the default HTML attributes for this mark
  addAttributes() {
    return {
      class: {
        default: 'mention',
      },
    };
  },

  // Define how this mark is rendered as HTML
  parseHTML() {
    return [
      {
        tag: 'span[class="mention"]',
      },
    ];
  },

  // Define how this mark is rendered in the editor
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), 0];
  },
});

export default HashtagMark

/*This JavaScript module is defining a custom mark for the tiptap editor, named HashtagMark.
 A mark in ProseMirror (the underlying library of tiptap) is a way to add inline formatting 
 to text, like bold or italic. In this case, the HashtagMark is used to add a specific CSS 
 class to hashtags. 
 addAttributes: This method is used to define the default HTML attributes for this mark. 
 In this case, it's adding a class attribute with the value 'mention'.*/