import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Badge,
  Container,
  createStyles,
  Text,
  Skeleton,
  Popover,
  Divider,
  Loader,
  NavLink,
} from "@mantine/core";
import { AuthContext } from "../context/Auth";

const Aboutwidget = () => {

  const { darkmode } = useContext(AuthContext);
  const navigate = useNavigate();

  {/* about */ }
  return (
    <div style={{ backgroundColor: darkmode ? "#1A1B1E" : "white", marginBottom: "0.5rem", borderRadius: "4px", padding: "1rem", color: darkmode ? "#c1c2c5" : "black", }}        >
      <div style={{ display: "flex", alignItems: "center", fontSize: "13px", gap: "1rem", }}>
        {" "}
        <div className="hoveru" onClick={() => { navigate("/about"); }} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "0.3rem", }}            >
          <Text>About</Text>
        </div>
        {/* 
            <div className="hoveru" onClick={() => {window.open("https://github.com/Nawang17/client-momo","_blank"                );              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              <Text>Github</Text>
            </div> */}
        <div
          className="hoveru"
          onClick={() => {
            window.open("https://discord.gg/QVcVaeJ8uH", "_blank");
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "0.3rem",
          }}
        >
          <Text>Discord</Text>
        </div>
        <div className="hoveru" onClick={() => { navigate("/privacy"); }} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "0.3rem", }}            >
          <Text>Privacy</Text>
        </div>

      </div>
      <Text
        style={{
          paddingTop: "1rem",
        }}
        size="12px"
      >
        © 2024 TV Posts Inc. All rights reserved.
      </Text>
    </div>
  );
};
export default Aboutwidget;