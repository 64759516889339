import {
  Group,
  Avatar,
  Text,
  Autocomplete,
  Box,
  MantineProvider
} from '@mantine/core';
import { MagnifyingGlass } from "phosphor-react";
import React, { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AutoCompleteItem = forwardRef((props, ref) => {
  const { description, value, image, imdbid, ...others } = props;

  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} size="lg" />
        <div>
          <Text size="m">{value}</Text>
          <Text size="s" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );
});

const Searchbox = ({ className, closeModal }) => {
  const [item, setItem] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [shows, setShows] = useState([]);

  const getTvTag = async (imdbid) => {
    let tvTag;
    try {
      // console.log("we need to see the imdbid!!!!!!!!!!!!!!!!!!", imdbid);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addtvshow/${imdbid}`);
      tvTag = response.data;
    } catch (error) {
      // console.error("Error occurred while updating the TV show: ", error);
    }
    return tvTag;
  };

  const handleChange = async (e) => {
    setValue(e);
    if (e.length >= 2) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tvshowinfo/search`, {
          params: { q: e }
        });
        const data = response.data.tvShowResults;

        const mapped = data.map((show) => ({
          image: show.showAvatar || 'https://via.placeholder.com/150',
          value: show.showName,
          description: show.showNetwork || 'N/A',
          imdbid: show.showIMDBId,
        }));

        setShows(mapped);
      } catch (error) {
        // console.error("Error occurred while fetching the shows: ", error);
      }
    }
  };
  
  return (
    <div className={className}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Box p="xl">
          <Autocomplete
            value={value}
            icon={<MagnifyingGlass size={16} />}
            placeholder="Search TV Posts"
            onChange={handleChange}
            data={shows}
            style={{ flex: 1, minWidth: 400 }}
            itemComponent={AutoCompleteItem}
            onItemSubmit={async (item) => {
              setItem(item);
              // console.log("this is the the iiiiitttteeemmm", item);
              const tvTag = await getTvTag(item.imdbid);
              // console.log("this is a TTTTVVVVTTTAAAGGG", tvTag);
              navigate((`/tv/${tvTag}`));
              if (closeModal) closeModal();
            }}
            filter={(value, item) =>
              item.value.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.description.toLowerCase().includes(value.toLowerCase().trim())
            }
          />
        </Box>
      </MantineProvider>
    </div>
  );
};

export default Searchbox;