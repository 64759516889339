import React from 'react';
import { NavLink } from '@mantine/core'; // Assuming NavLink is imported from mantine
import { useHover } from '@mantine/hooks';
import { Trash } from "phosphor-react"; //@phosphor-icons/react
// Assume TrashCanIcon is imported or defined somewhere

const CustomNavLink = ({ show, index, setActive, getWatchlistClick, handleDelete }) => {
  const { hovered, ref } = useHover();


  return (
    <div
      ref={ref}
      style={{ cursor: "pointer", display: 'flex', alignItems: 'top', justifyContent: 'space-between' }}
    >
      <NavLink
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              loading="lazy"
              style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: '10px' }}
              src={show.showAvatar}
              alt=""
            />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <div>{show.showName}</div>
              <div style={{ fontSize: '0.8em' }}>{show.showNetwork}</div>
            </div>
          </div>
        }
        onClick={() => {
          setActive(index);
          getWatchlistClick(show.tvTag);
        }}
        rightSection={
          hovered ? (
            <Trash
               style={{ marginLeft: 'auto', color:'grey' }}
               onClick={(event) => {
                 event.stopPropagation(); // Prevent the NavLink onClick event from firing
                 handleDelete(show.tvTag);
  }}
/>
          ) : (
            <div style={{ width: '24px', height: '24px' }}></div> // Transparent spacer
          )
        }
      />
    </div>
  );
};

export default CustomNavLink;