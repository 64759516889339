import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Popover,
  Container,
  Button,
  Divider,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Info } from "phosphor-react";
import { useState, useContext, useEffect } from "react";
import { googleauth, LoginStatus, RegisterReq } from "../../api/AUTH";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import confetti from "canvas-confetti";
import { AuthContext } from "../../context/Auth";
import GoogleLogin from "@leecheuk/react-google-login";
import { ShieldCheck, User, WarningCircle } from "phosphor-react";
import { isEmail } from 'validator';

export function Register({ socket }) {
  const navigate = useNavigate();
  const { setUserInfo, UserInfo, darkmode, setfollowingdata } =
    useContext(AuthContext);
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState(""); // Add Email state
  const [invitationCodeFromBody, setInvitationCodeFromBody] = useState("");
  const [loading, setloading] = useState(false);
  const [googleloading, setgoogleloading] = useState(false);
  const [error, seterror] = useState({
    email: '',
    username: '',
    password: '',
    invitationCode: '',
  });

  useEffect(() => {
    if (UserInfo) {
      navigate("/");
    }
  }, [UserInfo]);

  const handleRegister = (e) => {
    e.preventDefault();
    setloading(true);

    if (!isEmail(Email)) {
      seterror(prevState => ({
        ...prevState,
        email: 'Invalid email',
      }));
      setloading(false);
      return;
    }

    if (!validatePassword(Password)) {
      seterror(prevState => ({
        ...prevState,
        password: "Password must contain at least one upper case letter, one lower case letter, one digit, one special character and at least eight or more characters",
      }));
      setloading(false);
      return;
    }

    RegisterReq(Email, Username, Password, invitationCodeFromBody)
      .then((res) => {
        setUserInfo(res.data.user);
        localStorage.setItem("userid", res.data.user.userid); // Store userId in localstorage
        localStorage.setItem("username", res.data.user.username); // Store username in localstorage
        localStorage.setItem("token", res.data.token);
        socket.emit("onlinestatus", {
          token: res.data.token,
        });
        confetti({
          particleCount: 300,
          spread: 70,
          origin: { y: 0.6 },
        });

        showNotification({
          icon: <User size={18} />,
          title: "Register Successful",
          message: `Welcome to TV Posts ${res.data.user.username} `,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        const errors = err.response.data.errors; // Assuming backend sends an array of errors
        const updatedErrors = { email: '', username: '', password: '', invitationCode: '' };

        errors.forEach(error => {
          if (error.param === 'email') updatedErrors.email = error.msg;
          if (error.path === 'username') updatedErrors.username = error.msg;
          if (error.param === 'password') updatedErrors.password = error.msg;
          if (error.path === 'invitationCodeFromBody') updatedErrors.invitationCode = error.msg;
        });

        seterror(updatedErrors);
        setloading(false);
      });
  };
  const googleSuccess = (resp) => {
    setgoogleloading(true);
    googleauth(
      resp.profileObj.name,
      resp.profileObj.email,
      resp.profileObj.imageUrl
    )
      .then(async (res) => {
        setUserInfo(res.data.user);
        localStorage.setItem("userid", res.data.user.userid); // Store userId in localstorage
        localStorage.setItem("username", res.data.user.username); // Store username in localstorage
        localStorage.setItem("token", res.data.token);
        socket.emit("onlinestatus", {
          token: res.data.token,
        });
        navigate("/");
        if (res.data.type === "login") {
          showNotification({
            icon: <ShieldCheck size={18} />,
            title: "Login Successful",
            message: `Welcome back ${res.data.user.username}`,
            autoClose: 3000,
          });
          await LoginStatus().then((resp) => {
            setfollowingdata(resp.data.userfollowingarr);
          });
        } else if (res.data.type === "register") {
          confetti({
            particleCount: 300,
            spread: 70,
            origin: { y: 0.6 },
          });

          showNotification({
            icon: <User size={18} />,
            title: "Register Successful",
            message: `Welcome to TV Posts ${res.data.user.username} `,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 0) {
          showNotification({
            icon: <WarningCircle size={18} />,
            color: "red",
            title: "Internal Server Error",
            autoClose: 4000,
          });
        } else {
          showNotification({
            icon: <WarningCircle size={18} />,
            color: "red",
            title: err.response.data,
            autoClose: 4000,
          });
        }
        setgoogleloading(false);
      });
  };

  function validatePassword(password) {
    // At least one upper case English letter, one lower case English letter, one digit, one special character
    // and at least eight or more characters

    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  }

  return (
    <div style={{ height: "80vh" }}>
      <Container size={420} my={40}>
        <Title
          style={{
            color: darkmode ? "white" : "black",
          }}
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 700,
          })}
        >
          Welcome to TV Posts
        </Title>
        <Text color={"rgb(144, 146, 150)"} size="sm" align="center" mt={5}>
          Already have an account?{" "}
          <Link
            style={{
              textDecoration: "none",
              color: darkmode ? "rgb(77, 171, 247)" : "#1c7ed6",
            }}
            to="/Login"
          >
            <span>Login</span>
          </Link>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="sm">
          <form onSubmit={(e) => handleRegister(e)}>
            <TextInput
              value={Email}
              onChange={(e) => {
                setEmail(e.target.value);
                seterror(prevState => ({ ...prevState, email: '' }));
              }}
              label="Email"
              placeholder="Email"
              required
            />
            {error.email && <Text weight={"500"} color={"red"} size="sm">{error.email}</Text>}
            <TextInput
              value={Username}
              onChange={(e) => {
                setUsername(e.target.value);
                seterror(prevState => ({ ...prevState, username: '' }));
              }}
              label="Username"
              placeholder="Username"
              required
              mt="md"
            />
            {error.username && <Text weight={"500"} color={"red"} size="sm">{error.username}</Text>}
            <PasswordInput
              value={Password}
              onChange={(e) => {
                setPassword(e.target.value);
                seterror(prevState => ({ ...prevState, password: '' }));
              }}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Password</span>
                  <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                  <Popover width={280} position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <Info style={{ cursor: "pointer", marginLeft: '5px' }} size={15} />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Text size={"xs"}>
                        Your password must contain:
                      </Text>
                      <Divider my="xs" />


                      <Text size={"xs"}>- At least one uppercase letter</Text>
                      <Text size={"xs"}>- At least one lowercase letter</Text>
                      <Text size={"xs"}>- At least one digit</Text>
                      <Text size={"xs"}>- At least one special character</Text>
                      <Text size={"xs"}>- At least eight or more characters in total</Text>
                    </Popover.Dropdown>
                  </Popover>

                </div>
              }
              placeholder="Password"

              mt="md"
            />
            {error.password && <Text weight={"500"} color={"red"} size="sm">{error.password}</Text>}
            <TextInput
              value={invitationCodeFromBody}
              onChange={(e) => {
                setInvitationCodeFromBody(e.target.value);
                seterror(prevState => ({ ...prevState, invitationCode: '' }));
              }}
              label="Invitation Code"
              placeholder="Invitation Code"
              required
              mt="md"
            />
            {error.invitationCode && <Text weight={"500"} color={"red"} size="sm">{error.invitationCode}</Text>}
            <Button loading={loading} type="submit" fullWidth mt="xl">
              Register
            </Button>
          </form>
          <Divider
            style={{ marginTop: "15px" }}
            my="xs"
            label="OR"
            labelPosition="center"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            {/* Google Login - Need to change client ID */}
            <GoogleLogin
              clientId="933476491467-ou90tpjuc8gm4mbenn907d6jq4td1hkd.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  loading={googleloading}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  style={{ width: "100%" }}
                  leftIcon={
                    <img
                      width={"15px"}
                      height={"15px"}
                      src={require("../../assests/googleicon.png")}
                      alt=""
                    />
                  }
                  variant="default"
                  color="gray"
                >
                  Continue with Google
                </Button>
              )}
              onSuccess={(res) => googleSuccess(res)}
              onFailure={(res) => console.log(res)}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </Paper>
      </Container>
    </div>
  );
}