// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TiptapRTE {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.mention {
    color: #1d9bf0;
    font-style: normal !important;
    border-radius: 0.4rem;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
}

.ProseMirror {
    min-height: 200px;
    min-width: 300px;
    overflow-y: auto;
    /* Remove the focus outline */
    outline: none;
    /* Remove the border */
    border: none;
}

.ProseMirror {
    padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/tags-extensions/Tiptap.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,+BAA+B;IAC/B,WAAW;IACX,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,6BAA6B;IAC7B,qBAAqB;IACrB,mCAA2B;YAA3B,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".TiptapRTE {\n    background-color: #282c34;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n}\n\n.ProseMirror p.is-editor-empty:first-child::before {\n    color: #adb5bd;\n    content: attr(data-placeholder);\n    float: left;\n    height: 0;\n    pointer-events: none;\n}\n\n.mention {\n    color: #1d9bf0;\n    font-style: normal !important;\n    border-radius: 0.4rem;\n    box-decoration-break: clone;\n    padding: 0.1rem 0.3rem;\n}\n\n.ProseMirror {\n    min-height: 200px;\n    min-width: 300px;\n    overflow-y: auto;\n    /* Remove the focus outline */\n    outline: none;\n    /* Remove the border */\n    border: none;\n}\n\n.ProseMirror {\n    padding-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
