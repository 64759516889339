import React, { useState, useContext, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import axios from "axios";
import { Anchor, Container, createStyles, Loader } from "@mantine/core";
import { AuthContext } from "../context/Auth";
import { useNavigate } from "react-router-dom";

const Trendingticker = () => {

    const { darkmode } = useContext(AuthContext);
    const navigate = useNavigate();
    const [trendingArr, setTrendingArr] = useState([]);

    useEffect(() => {
        async function getData() {
          await axios.get(`${process.env.REACT_APP_API_URL}/trendingshows`)
            .then((res) => {
              setTrendingArr(res.data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      
        getData();
      }, []);
    
    
    return (
        <Container style={{
          maxWidth: "100%", // Add this line
          backgroundColor: darkmode ? "#1A1B1E" : "white",
          color: darkmode ? "white" : "black",
        }}>
          <Marquee style={{color:"white"}} speed ="25">
            Trending:
            {trendingArr.map((tvShowName, index) => (
              <>
                <span style={{ marginRight: "10px" }}></span>
                <Anchor
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/tv/${tvShowName.posttvtag}`);
                    
                  }}
                >
                  {tvShowName.posttvtag}
                </Anchor>
              </>
            ))}
          </Marquee>
        </Container>
      );

};

export default Trendingticker;