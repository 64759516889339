import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        value: 0,
    },
    reducers: {
        increment: state => {
            state.value += 1;
        },
        decrement: state => {
            state.value -= 1;
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
        decrementByAmount: (state, action) => {
            state.value -= action.payload;
        },
        incrementAsync: (state, action) => {
            // This would typically be handled with redux-thunk or redux-saga
        },
        decrementAsync: (state, action) => {
            // This would typically be handled with redux-thunk or redux-saga
        }
    },
});

export const { increment, decrement, incrementByAmount, decrementByAmount, incrementAsync, decrementAsync } = counterSlice.actions;

export const selectCount = state => state.counter.value;

export default counterSlice.reducer;