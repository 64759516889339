import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  createStyles,
  Divider,
  Indicator,
  NavLink,
  Popover,
  Progress,
  Skeleton,
  Text,
} from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import { CircleWavyCheck, Info } from "phosphor-react";
import { leaderboardinfo, userlevel } from "../api/GET";
import Watchlist from "./Watchlist";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    flex: 0.3,
    "@media (max-width: 700px)": {
      flex: 0,
      display: "none",
    },
  },
  mainwrapper: {
    width: "100%",
    top: "65px",
    position: "sticky",

    paddingBottom: "1rem",
  },
  accounts: {
    paddingTop: "0.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  title: {
    padding: "0.7rem 1rem 0 1rem",
  },
  account: {
    display: "flex",
    alignItems: "center",
    padding: "0.6rem 1rem 0.6rem 1rem",
    gap: "0.8rem",
    cursor: "pointer",
  },

  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
}));
export const Sidebar = () => {
  const { classes } = useStyles();
  const {
    darkmode,
    leaderboard,
    setLeaderboard,
    UserInfo,
    userlevelinfo,
    setUserlevelinfo,
    onlinelist,
    topUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const getLevel = () => {
    const points = userlevelinfo?.totalpoints;
    let level = Math.floor(points / 10);
    let progress = points % 10;
    return { level, progress };
  };
  function numberToOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  useEffect(() => {
    const getleaderboardinfo = async () => {
      setLoading(true);

      await leaderboardinfo(0)
        .then((res) => {
          setLeaderboard(res.data.leaderboard);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(true);
        });
    };
    const getuserlevel = async () => {
      await userlevel()
        .then((res) => {
          setUserlevelinfo(res.data.userlevel);
        })

        .catch(() => {
          setUserlevelinfo(null);
        });
    };
    getleaderboardinfo();
    if (UserInfo) {
      getuserlevel();
    } else {
      setUserlevelinfo(null);
    }
  }, [UserInfo]);
 
  
  
  
  {/***********Sidebar Rendering starts here*********/}
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainwrapper}>
      <Watchlist />
     </div>
 </div>
  );
};
