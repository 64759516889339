import DOMPurify from 'dompurify';

const cleanHTML = (dirty) => {
  return DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS: [
      'p', 'span', 'a', 'b', 'i', 'strong', 'em'
    ],
    ALLOWED_ATTR: [
      'data-type', 'data-id', 'style', 'class', 'href'
    ],
    ALLOWED_URI_REGEXP: /^https?:\/\/|^\/\//
  });
};

export default cleanHTML;
