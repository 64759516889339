import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, createStyles, Loader, Tabs, Text, Box, Button, Image, Group, Chip } from "@mantine/core";
import { PostFeed } from "../../Components/PostFeed";
import { Sidebar } from "../../Components/Sidebar";
//import { CreatePostModal } from "../../Components/CreatePostModal";
import CreatePostModal from "../../Components/CreatePostModal";
import "../../App.css";
import { Note, WarningCircle, CopySimple, Lock } from "phosphor-react";
import {
  getmorelikedposts,
  getmoreprofileposts,
  profileinfo,
} from "../../api/GET";
import { showNotification } from "@mantine/notifications";
import { AuthContext } from "../../context/Auth";
import InfiniteScroll from "react-infinite-scroll-component";
import CreatePost from "../../Components/CreatePost";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { incrementByAmount, decrementByAmount } from "../../Redux/counterslice";
import { getTvShowPosts } from "../../api/GET";

const useStyles = createStyles(() => ({

  //Style for main container with sidebar + posts
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    paddingBottom: "5rem",
    paddingTop: "0.5rem",
    "@media (max-width: 700px)": {
      paddingTop: "0rem",
    },
  },

  //Style for div containing tabs/posts
  leftWrapper: {
    width: "100%vw",
    flex: 0.7,
    "@media (max-width: 700px)": {
      flex: 1,
    },
  },
}));

const TVshow = () => {
  const counterValue = useSelector(state => state.counter.value); //used for redux
  console.log(counterValue); //redux

  const { UserInfo, darkmode, watchlist, setWatchlist } = useContext(AuthContext);


  const dispatch = useDispatch();

  const location1 = useLocation();
  const { pathname } = location1;
  console.log(pathname);
  //const imdbid = location.state.id;
  const { tvTag } = useParams();

  //const { userprofile } = useParams();
  // const { pathname } = useLocation();

  const userprofile = localStorage.getItem("username");
  const { classes } = useStyles();
  const [posts, setposts] = useState([]);
  const [profileInfo, setprofileInfo] = useState([]);
  const [loading, setloading] = useState(true);
  const [userlikedposts, setuserlikedposts] = useState([]);
  const [Tab, setTab] = useState("posts");
  const [postCount, setpostCount] = useState(0);
  const [postpage, setpostpage] = useState(0);
  const [likedpostCount, setlikedpostCount] = useState(0);
  const [likedpage, setlikedpage] = useState(0);
  const [rankinfo, setrankinfo] = useState([]);
  const [replies, setreplies] = useState([]);
  const [isWatching, setIsWatching] = useState(false); // Toggle Add to Watchlist/Watching Button
  const [buttonText, setButtonText] = useState("+ Watch");
  const [watchers, setWatchers] = useState(0); // Start with 0 watchers
  const [tvShowPosts, setTvShowPosts] = useState([]);
  const [page, setpage] = useState(0);
  const [tvShowInfo, setTvShowInfo] = useState([]);
  const [opened, setOpened] = useState(false);
  const [sortby, setsortby] = useState("Latest");
  const navigate = useNavigate();



  useEffect(() => {
    const watching = watchlist.some(show => show.tvTag === tvTag);
    //console.log('watchlist:!!!!!!!!!JOSEPH', watchlist, 'Type of watchlist:', typeof watchlist);

    setIsWatching(watching);
    if (watching) {
      setButtonText("Watching");
    } else {
      setButtonText("+ Watch");
    }
  }, [watchlist, tvTag]); // 

  useEffect(() => {
    const fetchtvposts = async () => {
      setpage(0);
      setloading(true);

      await getTvShowPosts(tvTag, 0) //Call the tvposts GET API
        .then((res) => {
          console.log('TVShow Posts from BE:', res.data.tvShowPosts);
          setTvShowInfo(res.data.tvShowInfo);
          setTvShowPosts(res.data.tvShowPosts);
          setWatchers(res.data.tvShowInfo.watchers);
          setloading(false);
          console.log('TVShow Post Count:', res.data.postCount);
          setpostCount(res.data.postCount);
          console.log('tvShowPosts.length:', res.data.tvShowPosts.length);
        })
        .catch((err) => {
          if (err.response.status === 0) {
            showNotification({
              icon: <WarningCircle size={18} />,
              color: "red",
              title: "Internal Server Error",
              autoClose: 4000,
            })
          };
        });
    }
    fetchtvposts();
  }, [tvTag]);

  useEffect(() => {
   // console.log("this is the context with the new watchlist pushed", watchlist);
  }, [watchlist]);

  {/*useEffect(() => {
    console.log("WAAATTTTCCCCHHHHEEERRRRRs", tvShowInfo.watchers);
    setWatchers(tvShowInfo.watchers);
  }, [tvShowInfo]); */}

  const fetchMorePosts = async () => {

    //console.log('Fetching more posts...');
    //console.log('Current page before increment:', page);

    setpage((prev) => prev + 1);

    //console.log('Current page after increment:', page + 1);
  
    await getTvShowPosts(tvTag, page + 1)
      .then((res) => {
        console.log(res.data);
        setTvShowPosts((prev) => [...prev, ...res.data.tvShowPosts]);
      })
      .catch((err) => {
        if (err.response.status === 0) {
          showNotification({
            icon: <WarningCircle size={18} />,
            color: "red",
            title: "Internal Server Error",
            autoClose: 4000,
          });
        } else {
          showNotification({
            icon: <WarningCircle size={18} />,
            color: "red",
            title: err.response.data,
            autoClose: 4000,
          });
        }
      });
  };
  


  const fetchMorelikedposts = () => {
    setlikedpage((prev) => prev + 1);

    getmorelikedposts(profileInfo?.id, likedpage + 1).then((res) => {
      setuserlikedposts((prev) => [...prev, ...res.data]);
    });
  };




  const addShowOrUpdateWatchers = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/tvshow/${tvShowInfo.showIMDBId}`) //NOTE: DELETE Notification here users don't need this message
      .then((response) => {
        if (response.status === 200) {
          showNotification({
            icon: <CopySimple size={18} />,
            title: "TV Show Added",
            autoClose: 3000,
            color: "red",
          });
        } else {
          showNotification({
            icon: <CopySimple size={18} />,
            title: "TV Show already in database",
            autoClose: 3000,
            color: "green",
          });
          // setWatchers(watchers => watchers + 1);
        }
      })
      .catch((e) => {
        console.log(e);
        showNotification({
          icon: <CopySimple size={18} />,
          title: "This is the catch block",
          autoClose: 3000,
          color: "red",
        });
      })
  };
  const updateWatchlist = async () => {
    const payload = {
      userId: localStorage.getItem('userid'),
      tvTag: tvShowInfo.tvTag,
    };

    if (buttonText === "+ Watch") {
      // Add the show to the watchlist and increment the watchers count
      await axios.post(`${process.env.REACT_APP_API_URL}/watchlist`, payload)
        .then(() => {
          showNotification({
            icon: <CopySimple size={18} />,
            title: "Added to watchlist",
            autoClose: 3000,
            color: "green",
          });
          dispatch(incrementByAmount(1));
          setButtonText("Watching");
          const newWatchers = watchers + 1;
          setWatchers(newWatchers);
          const newWatchlistRecord = {
            showName: tvShowInfo.showName,
            showNetwork: tvShowInfo.showNetwork,
            showAvatar: tvShowInfo.showAvatar,
            tvTag: tvShowInfo.tvTag
          }
          setWatchlist(prevWatchlist => [...prevWatchlist, newWatchlistRecord]);
        })

        .catch((e) => {
          console.log(e);
          showNotification({
            icon: <CopySimple size={18} />,
            title: "Must be logged in to add to watchlist",
            autoClose: 3000,
            color: "red",
          });
        });
    } else {
      // Remove the show from the watchlist and decrement the watchers count
      await axios.delete(`${process.env.REACT_APP_API_URL}/removefromwatchlist/${payload.userId}/${payload.tvTag}`)
        .then(() => {
          showNotification({
            icon: <CopySimple size={18} />,
            title: "Removed from watchlist",
            autoClose: 3000,
            color: "red",
          });
          dispatch(decrementByAmount(1));
          setButtonText("+ Watch");
          //const lessWatchers = watchers -1;
          setWatchers(prevWatchers => prevWatchers - 1);
          setWatchlist(prevWatchlist => prevWatchlist.filter(show => show.tvTag !== tvTag));
        })
        .catch((e) => {
          console.log(e);
          showNotification({
            icon: <CopySimple size={18} />,
            title: "Error removing from watchlist",
            autoClose: 3000,
            color: "red",
          });
        });
    }
  };

  {/* useEffect(() => {
    if (tvShowInfo && tvShowInfo.watchers) {
      setWatchers(tvShowInfo.watchers);
    }
  }, [tvShowInfo]);
*/}
  //Page rendering starts here

  {/* const addNewPost = (newPost) => {
    setTvShowPosts(prevPosts => [...prevPosts, newPost]);
  };
*/}


const handleOpenModal = () => {
  if (UserInfo) {
    setOpened(true);
  } else {
    showNotification({
      icon: <Lock size={18} />,
      title: "Login required",
      message: "You need to be logged in to create a post.",
      autoClose: 3000,
      color: "red",
    });
    // Optionally, you can still redirect to the login page
    // navigate('/login');
  }
};

  return (

    <Container px={0} size="xl" className={classes.wrapper} style={{ width: "100%" }}> {/*Main container for all elements below navbar, using flex in row layout */}

      {/*Sidebar for watchlist on left side */}
      {UserInfo && <Sidebar />}

      {/* Right container for TV Widget, Post Widget, and Post Feed, stacked in a column */}
      <div className={classes.leftWrapper}>

        {/*TV Show Info Widget */}
        <div style={{ display: "flex", justifyContent: "space-between", gap: "0.3rem", backgroundColor: darkmode ? "#1A1B1E" : "white", color: darkmode ? "white" : "black", padding: "1rem 2rem", marginBottom: "0.5rem ", overflow: "auto", }}> {/*//#1A1B1E*/}

          <Group>
            <Image width={70} fit="contain" mx="auto" radius="md" src={tvShowInfo.showAvatar} alt="Show Img" />
            <div>
              <Text>{tvShowInfo.showName}</Text>
              <Text>{tvShowInfo.showNetwork}</Text>
            </div>
          </Group>

          <Group>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

              <Button onClick={() => {
                //setWatchers(watchers => watchers + 1);
                updateWatchlist();

              }}>
                {buttonText}
              </Button>

              {/*<Chip checked={checked} onChange={updateWatchlist} >
             {checked ? "Watching" : "+ Watchlist"}
    </Chip>*/}
              <Text>{watchers} watchers</Text>
            </div>
          </Group>

        </div>
        {/*NEW CreatePost Widget */}
        <div
          className={
            darkmode ? "createpostmodalradiusdark" : "createpostmodalradius"
          }
          style={{
            padding: "1rem",
            backgroundColor: darkmode ? "#1A1B1E" : "white",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
            }}
          >

            <div
              onClick={handleOpenModal}
              style={{
                width: "100%",
                cursor: "pointer",

                padding: "0.7rem",
                fontSize: "13px",
                backgroundColor: darkmode ? "#1A1B1E" : "white",
                color: "grey",
              }}
            >
              Share your thoughts on {tvShowInfo.showName}
            </div>
          </div>
        </div>


        <CreatePostModal
          opened={opened}
          setOpened={setOpened}
          UserInfo={UserInfo}
          ShowImdbid={tvShowInfo.showIMDBId}
          tvTag={tvShowInfo.tvTag}
          setTvShowPosts={setTvShowPosts}
          runEffect={true}
        />


        {/* OLD Create Post Widget*/}



        {/*Post Feed Widget*/}
        <Tabs value={Tab} onTabChange={setTab}>
          <Tabs.List grow position="center" style={{ borderBottom: "none", backgroundColor: darkmode ? "#1A1B1E" : "white", }}>
            <Tabs.Tab value="posts" icon={<Note size={14} />}>
              Posts
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="posts" pt="xs">
            {tvShowPosts.length === 0 && !loading ? (
              <div style={{ padding: "1rem", }}>
                <Text color={darkmode ? "white" : "dark"} weight="bold" size="xl" align="center">
                  {tvShowInfo.showName} doesn't have any posts yet.
                </Text>
                <Text align="center" size={"sm"} color={"dimmed"}>
                  Be the first to post about {tvShowInfo.showName} here!
                </Text>
              </div>
            ) : (
              tvShowPosts && (
                <InfiniteScroll 
                  dataLength={tvShowPosts.length} 
                  next={() => {
                    console.log('InfiniteScroll triggered!!!!!!!!!!!'); // Log to check if InfiniteScroll is triggered
                    fetchMorePosts(); // Your function to fetch more posts
                  }} 
                  hasMore={postCount > tvShowPosts.length} 
                  loader={
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", }}>
                  <Loader />
                  </div>
                }
                endMessage={
                  <>
                     <Text
                        style={{
                          marginTop: "1rem",
                        }}
                        align="center"
                        color={darkmode ? "white" : "dark"}
                      >
                        You have seen it all for this show.
                      </Text> 
                  </>
                }
                >
                  <PostFeed 
                  posts={tvShowPosts} 
                  loading={loading} 
                  setPosts={setTvShowPosts} />
                </InfiniteScroll>
              )
            )}
          </Tabs.Panel>
        </Tabs>
      </div>
    </Container>
  );
};

export default TVshow;